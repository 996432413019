import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { ConfigurationDetailForm } from "./ConfigurationDetailForm/ConfigurationDetailForm";
import { ConfigurationDetailTable } from "./ConfigurationDetailTable/ConfigurationDetailTable";

export const ConfigurationDetailView = () => {
  const {
    url,
    params: { config_id },
  } = useRouteMatch<{ config_id: string }>();
 

  return (
    <Grid style={{ margin: "1em" }}>
      <Grid item xs>
        <Typography variant="h4">Configuration Detail</Typography>
      </Grid>
      <Grid item xs></Grid>
      <Grid item xs>
        <Switch>
          <Route
            exact
            path={url}
            render={() => (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`${url}/new`}
                >
                  Add
                </Button>
                <ConfigurationDetailTable configId={String(config_id)} />
              </>
            )}
          />
          <Route
            exact
            path={`${url}/:id`}
            render={() => (
              <ConfigurationDetailForm configId={String(config_id)} />
            )}
          />
        </Switch>
      </Grid>
    </Grid>
  );
};
