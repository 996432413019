import MaterialTable from "material-table";
import React, { FC } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  deleteConfigurationDetail,
  getConfigurationDetails,
} from "../../queries";
import { HomeConfigurationDetailItem } from "../../types";
import { getConfigurationDetailColumns } from "./getConfigurationDetailColumns";

type ConfigurationViewProps = {
  configId: string;
};

export const ConfigurationDetailTable: FC<ConfigurationViewProps> = ({configId}) => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const { isLoading, data: configurationDetails = [] } = useQuery<
    HomeConfigurationDetailItem[]
  >(
    ["homeConfigs", configId],
    () => getConfigurationDetails(Number(configId)),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columns = getConfigurationDetailColumns();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteConfig } = useMutation(deleteConfigurationDetail, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      columns={columns}
      data={configurationDetails}
      options={{ showTitle: false }}
      onRowClick={(_, config) => push(`${url}/${config?.id}`)}
      editable={{
        onRowDelete: async (data) => {
          await deleteConfig(Number(data.id));
          return true;
        },
      }}
    />
  );
};
