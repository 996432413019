import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { api } from "../../api";
import { getInCompleteLogin } from "../../queries/student";
import { InCompleteLogins } from "../../shared/modelTypes";
import { Passcode } from "../Administration/Passcode";

export const InCompleteLogin = () => {
  const queryClient = useQueryClient();
  const [passCode, setPassCode] = useState<string>("");
  const [show, setShow] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [regId, setRegId] = useState(0);
  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    if (passCode === "phoenix125") {
      setShow(false);
    }
    setIsError(true);
  };
  const { isLoading, data: inCompleteLogin = [] } = useQuery(
    "students/incomplete_login",
    getInCompleteLogin,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columnDefintion: Column<InCompleteLogins>[] = [
    {
      title: "Username",
      field: "user_name",
    },

    {
      title: "Contact Name",
      field: "full_name",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "Created at",
      field: "created_at",
      type: "date",
      render: ({ created_at }) => {
        return moment(created_at)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },
  ];

  const showConfirmation = (id: number) => {
    setConfirm(true);
    setRegId(id);
  };

  const markComplete = async () => {
    let response;
    try {
      response = await api.patch(
        `students/close-incomplete-login/${regId}`,
        undefined,
        {}
      );

      setConfirm(false);
      setRegId(0);
      queryClient.invalidateQueries();
    } catch (errorReq) {
      console.log(errorReq);
    }
    return response;
  };

  return show === false ? (
    <>
      <MaterialTable
        isLoading={isLoading}
        title="In-Complete Login"
        columns={columnDefintion.map((c) => ({
          ...c,
          tableData: undefined,
        }))}
        data={inCompleteLogin}
        actions={[
          {
            icon: "done",
            tooltip: "Mark as Complete",
            onClick: (event, rowData: any) => showConfirmation(rowData.user_id),
          },
        ]}
      />
      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to mark as complete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(false)}>Cancel</Button>
          <Button onClick={markComplete}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Passcode
      passCode={passCode}
      isErrors={isError}
      setPassCode={setPassCode}
      handleClose={handleClose}
    />
  );
};
