import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ConfigurationDetailView } from "./ConfigurationDetailView/ConfigurationDetailView";
import { ConfigurationTable } from "./ConfigurationTable/ConfigurationTable";
import {AddConfiguration} from "./ConfigurationTable/AddConfiguration";

export const AppHomeConfiguration = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <ConfigurationTable />} />
      <Route
        path={`${url}/deatils/:config_id`}
        render={() => <ConfigurationDetailView />}
      />
      <Route exact path={`${url}/new`} render={() => <AddConfiguration />} />
      <Route exact path={`${url}/edit`} render={() => <AddConfiguration />} />
    </Switch>
  );
};
