import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import {
  createRendition,
  deleteRendition,
  getRendition,
  updateRendition,
} from "../../../queries/mediaRendtion";
import { getRenditionConfig } from "../../../queries/renditionConfig";
import { getAllS3Bucket } from "../../../queries/s3bucket";
import {
  MediaRendition,
  RenditionConfig,
  S3BucketType,
} from "../../../shared/modelTypes";
import { reactSelectCustomStyle } from "../../../theme/theme";
import Select from "react-select";

export const MediaRenditions = () => {
  const sourceId = new URLSearchParams(useLocation().search).get("source_id");
  const queryClient = useQueryClient();

  const { isLoading, data: rendition = [] } = useQuery<MediaRendition[]>(
    ["media/rendition", sourceId],
    () => getRendition(sourceId),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: s3Buckets = [] } = useQuery<S3BucketType[]>(
    "config/s3_buckets",
    getAllS3Bucket,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: renditionConfig = [] } = useQuery<RenditionConfig[]>(
    "media/rendition_config",
    getRenditionConfig,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columnDefintion: Column<MediaRendition>[] = [
    {
      title: "Title",
      field: "rendition_config_id",
      lookup: renditionConfig.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: (rowData) => Boolean(rowData.rendition_config_id),
    },

    {
      title: "Bucket",
      field: "bucket_id",
      lookup: s3Buckets.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: (rowData) => Boolean(rowData.bucket_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={s3Buckets.map((bucket: { id: any; title: any }) => ({
              value: bucket.id,
              label: bucket.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "M3 Playlist path",
      field: "file_path",
      validate: (rowData) => Boolean(rowData.file_path),
    },
    {
      title: "Downloaded file path",
      field: "converted_file_path",
    },
  ];

  const { mutateAsync: mutAddMediaRendition } = useMutation(createRendition, {
    onSuccess: (newRendition) => {
      queryClient.invalidateQueries("media/rendition");
    },
  });

  const { mutateAsync: mutEditMediaRendition } = useMutation(updateRendition, {
    onSuccess: (updatedRendition) => {
      queryClient.invalidateQueries("media/rendition");
    },
  });

  const { mutateAsync: mutDeleteMediaRendition } = useMutation(
    deleteRendition,
    {
      onSuccess: (deletedRendition) => {
        queryClient.invalidateQueries("media/rendition");
      },
    }
  );

  return (
    <>
      <MaterialTable
        isLoading={isLoading}
        data={rendition}
        title="Media Rendition"
        columns={columnDefintion.map((c) => ({
          ...c,
          tableData: undefined,
        }))}
        editable={{
          onRowAdd: (rendition) => {
            rendition.media_source_id = Number(sourceId);
            return mutAddMediaRendition(rendition);
          },
          onRowUpdate: (mediaRendition) => {
            mediaRendition.media_source_id = Number(sourceId);
            return mutEditMediaRendition({
              id: mediaRendition.id,
              payload: mediaRendition,
            });
          },
          onRowDelete: mutDeleteMediaRendition,
        }}
      />
    </>
  );
};
