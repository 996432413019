import { MutationFunction } from "react-query";
import { api } from "../api";
import { Subject, System, Topic } from "../shared/modelTypes";

export const getAllSubjects = () => {
  return api.get("subjects");
};

export const getAllCbmeSubjects = (
  type: string | null | undefined = undefined
) => {
  return api.get("subjects", type ? { type: type } : undefined);
};

export const createSubject: MutationFunction<
  Subject,
  Partial<Subject> | FormData
> = (subject) => {
  return api.post("subjects", undefined, subject);
};

export const deleteSubject: MutationFunction<Subject, Partial<Subject>> =
  async (subject) => {
    return api.delete(`subjects/${subject.id}`);
  };

export const updateSubject: MutationFunction<
  Subject,
  { id: number; payload: Partial<Subject> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`subjects/${id}`, undefined, payload);
};

// systems
export const getAllSystems = (
  subjectId: string | null | undefined = undefined
) => {
  return api.get("systems", subjectId ? { subject_id: subjectId } : undefined);
};

export const createSystem: MutationFunction<
  System,
  Partial<System> | FormData
> = async (system) => {
  return api.post("systems", undefined, system);
};

export const deleteSystem: MutationFunction<System, Partial<System>> = async (
  system
) => {
  return api.delete(`systems/${system.id}`);
};

export const updateSystem: MutationFunction<
  System,
  { id: number; payload: Partial<System> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`systems/${id}`, undefined, payload);
};

// topics
export const getAllTopics = (
  topicId: string | null | undefined = undefined
) => {
  return api.get("topics", topicId ? { topic_id: topicId } : undefined);
};

export const createTopic: MutationFunction<Topic, Partial<Topic> | FormData> =
  async (topic) => {
    return api.post("topics", undefined, topic);
  };

export const deleteTopic: MutationFunction<Topic, Partial<Topic>> = async (
  topic
) => {
  return api.delete(`topics/${topic.id}`);
};

export const updateTopic: MutationFunction<
  Topic,
  { id: number; payload: Partial<Topic> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`topics/${id}`, undefined, payload);
};
