import {
  AppBar,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { SetStateAction, useState } from "react";
import { StudentProfile } from "./StudentProfile";
import { StudentSubscription } from "./StudentSubscription";
import { useQuery } from "react-query";
import { getStudentProfile } from "../../queries/student";
import { StudentDevice } from "./StudentDevice";
import { StudentBookmarks } from "./StudentBookmarks";
import { StudentWatchHistory } from "./StudentWatchHistory";
import { useLocation } from "react-router-dom";
import {CbmeSubscription} from './CbmeSubscription'


export type StudentProfile = {
  id: string;
  email: string;
  phone: string;
  gender: string;
  full_name: string;
  avatar_path: string;
  is_disabled: boolean;
};

export const Student = () => {
  const email = new URLSearchParams(useLocation().search).get("email");
  const type = 'cbme';
  const [value, setValue] = useState(0);
  // const [searchValue, setSearchValue] = useState("");
  // const queryClient = useQueryClient();
 
  const handleChange = (event: any, newValue: SetStateAction<number>) => {
    setValue(newValue);
  };

  // const onhandleSearchValue = (event: any) => {
  //   setSearchValue(event.target.value);
  //   queryClient.removeQueries(profiles);
  // };

  const { data: profiles = {} } = useQuery(
    ["students/profile",email],
    () => getStudentProfile(email),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  // const getProfile = async () => {
  //   const type = Number(searchValue);
  //   if (isNaN(type) !== true) {
  //     if (searchValue.length < 13) {
  //       alert("Wrong number/add telephone code(eg:+91)");
  //     } else {
  //       refetch();
  //     }
  //   } else {
  //     refetch();
  //   }
  // };

  return (
    <Grid container direction="column" style={{ padding: "1em" }}>
      <Grid item xs="auto">
        <Typography variant="h6">View Profile</Typography>
      </Grid>

      {/* <Paper style={{ padding: "1em" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="E-mail/Mobile"
              variant="outlined"
              value={searchValue}
              onChange={onhandleSearchValue}
            />
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="contained" onClick={getProfile}>
              View Profile
            </Button>
          </Grid>
        </Grid>
      </Paper> */}

      {/* {!Object.keys(profiles).length ||
      Object.keys(profiles).includes("error") ? (
        <Grid style={{ paddingTop: "1em" }}>
          {" "}
          <Typography variant="h6">Type your e-mail/mobile</Typography>
        </Grid>
      ) : ( */}
        <Grid style={{ paddingTop: "1em" }}>
          <Paper>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Profile" />
                <Tab label="Subscription" />
                <Tab label="Cbme Subscription" />
                <Tab label="Device" />
                <Tab label="Bookmark" />
                <Tab label="Watch History" />
              </Tabs>
            </AppBar>
            <StudentProfile children={profiles} value={value} index={0} />
            <StudentSubscription
              children={email as string}
              value={value}
              index={1}
              studentid={(profiles as StudentProfile).id}
            />
            <CbmeSubscription 
              children={email as string}
              value={value}
              type={type as string}
              index={2}
              studentid={(profiles as StudentProfile).id}
            />

            <StudentDevice children={email} value={value} index={3} />
            <StudentBookmarks children={email} value={value} index={4} />
            <StudentWatchHistory
              children={email}
              value={value}
              index={5}
            />
          </Paper>
        </Grid>
       {/* )} */}
    </Grid>
  );
};
