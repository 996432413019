import { MutationFunction } from "react-query";
import { api } from "../../../api";
import { HomeConfigurationItems } from "../../../shared/modelTypes";
import { HomeConfigurationDetailItem, HomeConfigurationItem } from "./types";

export const getAllConfigurations = () => api.get("/config/home_config");

export const createConfiguration: MutationFunction<
  HomeConfigurationItems,
  Partial<HomeConfigurationItems>
> = (configuration) => {
  let payload = {};
  if (configuration.applicable_colleges !== undefined) {
    const collegeList = configuration.applicable_colleges?.map(
      (item) => item.value
    );
    payload = { ...configuration, applicable_colleges: collegeList };
  }
  
  return api.post(`/config/home_config`, undefined, payload);
};

export const editConfiguration: MutationFunction<
  HomeConfigurationItem,
  Partial<HomeConfigurationItem>
> = (configuration) => {
  return api.patch(
    `/config/home_config/${configuration.id}`,
    undefined,
    configuration
  );
};

export const delteConfiguration: MutationFunction<boolean, number> = (
  configId
) => {
  return api.delete(`/config/home_config/${configId}`);
};

export const getConfigurationDetails = (id: number) =>
  api.get(`/config/home_config/${id}`);

export const deleteConfigurationDetail = (id: number) =>
  api.delete(`/config/home_config/detail/${id}`);

export const createConfigurationDetail: MutationFunction<
  HomeConfigurationDetailItem,
  { configId: number; payload: Partial<HomeConfigurationDetailItem> | FormData }
> = async ({ configId, payload }) => {
  return api.post(`/config/home_config/${configId}/detail`, undefined, payload);
};

export const updateHomeConfigurationDetail: MutationFunction<
  HomeConfigurationDetailItem,
  { id: number; payload: Partial<HomeConfigurationDetailItem> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`/config/home_config/detail/${id}`, undefined, payload);
};
