import { Typography } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "../App.css";
import { NavItem } from "../components/SideDrawer/types";
import { routeConfig } from "../routeConfig";
import { HeaderBar } from "./HeaderBar";

export const AppViews = () => {
  const generateRoutes = (routes: NavItem[]): any =>
    routes
      .map((route) => {
        if (route.subRoutes) {
          return generateRoutes(route.subRoutes);
        }
        return (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
          />
        );
      })
      .flat();
  return (
    <>
      <HeaderBar />
      <Switch>
        {generateRoutes(routeConfig)}
        <Route
          render={() => (
            <Typography variant="h3">That route doesn't exist (yet)</Typography>
          )}
        />
      </Switch>
    </>
  );
};
