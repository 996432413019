import { MutationFunction } from 'react-query';
import { api } from '../api';
import { State } from '../shared/modelTypes';

export const getAllState = () => {
  return api.get('students/states');
};

export const createState: MutationFunction<State, Partial<State>> = async (
  state
) => {
  return api.post('students/add-state', undefined, state);
};

export const updateState: MutationFunction<
  State,
  { id: number; payload: Partial<State> }
> = async ({ id, payload }) => {
  return api.patch(`students/edit-state/${id}`, undefined, payload);
};

export const deleteState: MutationFunction<State, Partial<State>> = async (
  state
) => {
  return api.delete(`students/delete-state/${state.id}`);
};
