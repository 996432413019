import { MutationFunction } from "react-query";
import { api } from "../../../api";

export type Premier = {
  id: number;
  media_source_id: number;
  thumbnail_bucket_id: number | null;
  title: string;
  description: string;
  topics_list: string;
  thumbnail_file_path: string | null;
  available_from: string | Date;
  available_through: string | Date;
  thumbnail_url?: string | null;
  thumbnail_blob?: string | Blob;
  flash_note_bucket_id?: number | null;
  flash_note_filepath?: string | null;
};
export const getAllPremieres = () => {
  return api.get("media/premieres");
};

export const addPremier: MutationFunction<
  Premier,
  Partial<Premier> | FormData
> = async (premier) => {
  return api.post("media/premieres", undefined, premier);
};

export const deletePremier: MutationFunction<Premier, number> = async (id) => {
  return api.delete(`media/premieres/${id}`);
};

export const updatePremier: MutationFunction<
  Premier,
  { id: number; payload: Partial<Premier> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`media/premieres/${id}`, undefined, payload);
};
