import MaterialTable, { Column } from "material-table";
import moment from "moment";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllCbmeSubjects, getAllSubjects } from "../../queries/categories";
import { getSubjectsPricing } from "../Sales/SubjectPricing/queries";
import {
  createStudentSubscription,
  deleteStudentSubsription,
  getStudentSubscription,
  updateStudentSubsription,
} from "../../queries/student";
import { StudentSubscriptions } from "../../shared/modelTypes";

export const CbmeSubscription = (props: {
  [x: string]: any;
  children: string;
  value: any;
  index: any;
  type: any
  studentid: string;
}) => {
  const {type, children, value, index, ...other } = props;
  const { isLoading, data: subscriptions = [] } = useQuery(
    ["students/subscription", children, type],
    () => getStudentSubscription(children, type),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { data: subjects = [] } = useQuery(["subjects", type], () => getAllCbmeSubjects(type), {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  console.log(subjects)
  const { data: pricing = [] } = useQuery(
    "subjects_pricing",
    getSubjectsPricing,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columnDefintion: Column<StudentSubscriptions>[] = [
    {
      title: "Package",
      field: "cbme_package_id",
      lookup: subjects.reduce(
        (acl: any, curr: { package_id: any; title: any }) => ({
          ...acl,
          [curr.package_id]: curr.title,
        }),
        {}
      ),
      editable: 'onAdd'
    },
    // {
    //   title: "Subject",
    //   field: "subject_id",
    //   lookup: subjects.reduce(
    //     (acl: any, curr: { id: any; title: any }) => ({
    //       ...acl,
    //       [curr.id]: curr.title,
    //     }),
    //     {}
    //   ),
    //   editable: "never",
    //   emptyValue: "-",
    // },

    {
      title: "From",
      field: "valid_from",
      type: "date",
      initialEditValue: moment().startOf("minute"),
    },

    {
      title: "To",
      field: "valid_through",
      type: "date",
      initialEditValue: moment().startOf("minute"),
    },
  ];

  const queryClient = useQueryClient();

  const { mutateAsync: mutCreateSubscription } = useMutation(
    createStudentSubscription,
    {
      onSuccess: (newSubscription) => {
        queryClient.setQueryData<Partial<StudentSubscriptions>[]>(
          ["students/subscription", children,type],
          (prev) => [...(prev || []), newSubscription]
        );
      },
    }
  );
  // const { mutateAsync: mutDeleteSubscription } = useMutation(
  //   deleteStudentSubsription,
  //   {
  //     onSuccess: (params) => {
  //       queryClient.invalidateQueries();
  //     },
  //   }
  // );
  const { mutateAsync: mutUpdateSubscription } = useMutation(
    updateStudentSubsription,
    {
      onSuccess: (updatedSubscription) => {
        queryClient.invalidateQueries();
      },
    }
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MaterialTable
          isLoading={isLoading}
          title="Subjects"
          data={subscriptions}
          columns={columnDefintion.map((c) => ({
            ...c,
            tableData: undefined,
            student_id: other.studentid,
          }))}
          editable={{
            onRowAdd: (updatingSubscription) => {
              if (updatingSubscription.package_id) {
                let subjectid;
                pricing.map((pack: any) => {
                  if (pack.id == updatingSubscription.package_id) {
                    subjectid = pack.subject_id;
                  }
                });
                if (subjectid != undefined) {
                  const subject = {
                    subject_id: subjectid,
                  };
                  const updatingSubscription1 = {
                    ...updatingSubscription,
                    ...subject,
                  };
                  return mutCreateSubscription({
                    id: Number(other.studentid),
                    subscription: updatingSubscription1,
                    type
                  });
                }
              }
              return mutCreateSubscription({
                id: Number(other.studentid),
                subscription: updatingSubscription,
                type
              });
            },

            onRowUpdate: (updatingSubscription) => {
              if (updatingSubscription.package_id) {
                let subjectid;
                pricing.map((pack: any) => {
                  if (pack.id == updatingSubscription.package_id) {
                    subjectid = pack.subject_id;
                  }
                });
                if (subjectid != undefined) {
                  const subject = {
                    subject_id: subjectid,
                  };
                  const updatingSubscription1 = {
                    ...updatingSubscription,
                    ...subject,
                  };
                  return mutUpdateSubscription({
                    id: updatingSubscription.id,
                    payload: updatingSubscription1,
                    type
                  });
                }
              }
              return mutUpdateSubscription({
                id: updatingSubscription.id,
                payload: updatingSubscription,
                type
              });
            },
            // onRowDelete: mutDeleteSubscription,
          }}
        />
      )}
    </div>
  );
};
