import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, {
  createContext,
  Dispatch,
  FC,
  useEffect,
  useReducer,
} from "react";
import { api } from "../api";
import { AlertDialog } from "../shared/AlertDialog";
import { AppAction } from "./AppActionType";
import { appReducer } from "./appReducer";
import { AppState } from "./AppStateType";

const initialState: AppState = {
  portal: { isAuthenticated: undefined },
};

type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
};

const defaultContext: AppContextType = {
  state: initialState,
  dispatch: () => {},
};

export const AppContext = createContext<AppContextType>(defaultContext);

export const AppProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const {
    portal: { snack, alert },
  } = state;

  useEffect(() => {
    api.setServerErrorHandler((error) => {
      dispatch({
        type: "show_alert",
        payload: {
          title: "Oops!, Something went wrong!",
          description: `${error.error}\n${error.error_code}`,
        },
      });
    });
  }, []);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!snack}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch({ type: "show_snack", payload: null });
        }}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      >
        <Alert severity={snack?.severity}>{snack?.text}</Alert>
      </Snackbar>
      <AlertDialog
        title={alert?.title}
        description={alert?.description}
        showAlert={!!alert}
        onClose={() => dispatch({ type: "show_alert", payload: null })}
      />
    </AppContext.Provider>
  );
};
