import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC, useState } from "react";
import { AppConfigurationItem } from "../../types";
import { GroupSelector } from "./GroupSelector";
import { LiveStreamSelector } from "./LiveStreamSelector";
import { SubjectSelector } from "./SubjectSelector";
import { SystemSelector } from "./SystemSelector";
import { TopicSelector } from "./TopicSelector";
import { VideoSelector } from "./VideoSelector";

const dialogForms: {
  [key in AppConfigurationItem]: {
    render: FC<{ onSelect: (values: any[]) => void }>;
  };
} = {
  group: {
    render: GroupSelector,
  },
  promotion: {
    render: () => <h1>promotion</h1>,
  },
  subject: {
    render: SubjectSelector,
  },
  system: {
    render: SystemSelector,
  },
  topic: {
    render: TopicSelector,
  },
  video: {
    render: VideoSelector,
  },
  flash_note: {
    render: VideoSelector,
  },
  live_stream: {
    render: LiveStreamSelector,
  },
};

type AddItemsDialogProps = {
  open: boolean;
  onDialogClose: (items: null | any[]) => void;
  addType: AppConfigurationItem;
};

export const AddItemsDialog: FC<AddItemsDialogProps> = ({
  open,
  onDialogClose,
  addType,
}) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  return (
    <Dialog
      open={open}
      onClose={() => onDialogClose(null)}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Add Items {addType}</DialogTitle>
      <DialogContent>
        {dialogForms[addType]?.render({ onSelect: setSelectedItems })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDialogClose(null)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onDialogClose(selectedItems)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
