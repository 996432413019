import MaterialTable, { Column } from "material-table";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createAppReleaseVersion,
  deleteAppReleaseVersion,
  getAllAppReleaseVersion,
  updateAppReleaseVersion,
} from "../../../queries/appReleaseVersion";
import { AppReleaseVersion } from "../../../shared/modelTypes";
import * as React from "react";

export const AppReleaseVersions = () => {
  const { isLoading, data: releaseVersion = [] } = useQuery(
    "config/app_release_version",
    getAllAppReleaseVersion,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const columnDefintion: Column<AppReleaseVersion>[] = [
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Major",
      field: "major",
    },

    {
      title: "Minor",
      field: "minor",
    },
    {
      title: "Patch",
      field: "patch",
    },
    {
      title: "Platform",
      field: "platform",
      validate: (rowData) => Boolean(rowData.platform),
    },
    {
      title: "Force update",
      field: "force_update",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: "Release date",
      field: "release_date",
      type: "datetime",
      initialEditValue: moment().startOf("minute"),
      render: ({ release_date }) => {
        return moment(release_date)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
    {
      title: "Force update version number",
      field: "force_update_version",
    },
  ];

  const queryClient = useQueryClient();

  const { mutateAsync: mutCreateAppRelease } = useMutation(
    createAppReleaseVersion,
    {
      onSuccess: (newRelease) => {
        queryClient.setQueryData<Partial<AppReleaseVersion>[]>(
          "config/app_release_version",
          (prev) => [...(prev || []), newRelease]
        );
      },
    }
  );

  const { mutateAsync: mutDeleteAppRelease } = useMutation(
    deleteAppReleaseVersion,
    {
      onSuccess: (params) => {
        queryClient.invalidateQueries();
      },
    }
  );

  const { mutateAsync: mutUpdateAppRelease } = useMutation(
    updateAppReleaseVersion,
    {
      onSuccess: (updatedRelease) => {
        queryClient.invalidateQueries();
      },
    }
  );

  return (
    <MaterialTable
      isLoading={isLoading}
      data={releaseVersion}
      title="App Release Versions"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutCreateAppRelease,
        onRowUpdate: (updatingBucket) =>
          mutUpdateAppRelease({
            id: updatingBucket.id,
            payload: updatingBucket,
          }),
        onRowDelete: mutDeleteAppRelease,
      }}
    />
  );
};
