import { MutationFunction } from "react-query";
import { api } from "../api";
import { MediaRendition } from "../shared/modelTypes";

export const getRendition = (
  sourceId: string | null | undefined = undefined
) => {
  return api.get(
    "media/rendition",
    sourceId ? { source_id: sourceId } : undefined
  );
};

export const createRendition: MutationFunction<
  MediaRendition,
  Partial<MediaRendition>
> = async (rendition) => {
  return api.post("media/rendition", undefined, rendition);
};

export const updateRendition: MutationFunction<
  MediaRendition,
  { id: number; payload: Partial<MediaRendition> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`media/rendition/${id}`, undefined, payload);
};

export const deleteRendition: MutationFunction<
  MediaRendition,
  Partial<MediaRendition>
> = async (rendition) => {
  return api.delete(`media/rendition/${rendition.id}`);
};
