import { MutationFunction } from "react-query";
import { api } from "../../../api";
import { SalesTaxItem } from "./types";

export const getAllSalesTaxes = () => api.get("/sales/sales_taxes");

export const createSalesTax = (taxItem: Omit<SalesTaxItem, "id">) =>
  api.post(`/sales/sales_taxes`, undefined, taxItem);

export const editSalesTax: MutationFunction<
  SalesTaxItem,
  Partial<SalesTaxItem>
> = (taxItem: Partial<SalesTaxItem>) => {
  const { id, ...rest } = taxItem;
  return api.patch(`/sales/sales_taxes/${id}`, undefined, rest);
};

export const deleteSalesTax: MutationFunction<boolean, number> = (id: number) =>
  api.delete(`/sales/sales_taxes/${id}`);
