import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { routeConfig } from "../../routeConfig";
import { CollapsibleMenu } from "./CollapsibleMenu";

type SideDrawerProps = {
  showDrawer: boolean;
  onDrawerClose: () => void;
};
export const SideDrawer: FC<SideDrawerProps> = ({
  showDrawer,
  onDrawerClose,
}) => {
  return (
    <Drawer
      variant="temporary"
      anchor={"left"}
      open={showDrawer}
      onClose={onDrawerClose}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Dr Polaris Administration
          </ListSubheader>
        }
      >
        {routeConfig.map((config) => {
          if (config.subRoutes) {
            return (
              <CollapsibleMenu
                key={config.title}
                title={config.title}
                menuItems={config.subRoutes}
                onClose={onDrawerClose}
              />
            );
          }
          return (
            <ListItem
              key={config.title}
              button
              component={Link}
              to={config.path || ""}
              onClick={onDrawerClose}
            >
              <ListItemText primary={config.title} />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};
