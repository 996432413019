import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AppContext } from "../../../AppContext";
import { getSalesTaxColumns } from "./getSalesTaxColumns";
import * as queries from "./queries";
import { SalesTaxItem } from "./types";

export const SalesTax = () => {
  const appContext = useContext(AppContext);

  const { data: salesTax = [], isLoading } = useQuery(
    "salesTax",
    queries.getAllSalesTaxes,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  const { mutateAsync: createSalesTax } = useMutation(queries.createSalesTax, {
    onSuccess: (newPricing) => {
      queryClient.setQueryData<Partial<SalesTaxItem>[]>("salesTax", (prev) => [
        ...(prev || []),
        newPricing,
      ]);
    },
  });

  const { mutateAsync: editSalesTax } = useMutation(queries.editSalesTax, {
    onSuccess: () => {
      queryClient.invalidateQueries("salesTax");
    },
  });

  const { mutateAsync: deleteSalesTax } = useMutation<
    boolean,
    { error: string },
    number
  >(queries.deleteSalesTax, {
    onSuccess: (params) => {
      queryClient.invalidateQueries("salesTax");
    },
    onError: ({ error }) =>
      appContext.dispatch({
        type: "show_snack",
        payload: {
          severity: "error",
          text: error,
        },
      }),
  });
  const columnConfig = getSalesTaxColumns();

  return (
    <Grid container direction="column" style={{ padding: "1em" }}>
      <Grid item xs="auto">
        <MaterialTable
          columns={columnConfig}
          data={salesTax}
          editable={{
            onRowAdd: createSalesTax,
            onRowDelete: async ({ id }) => deleteSalesTax(id),
            onRowUpdate: (tax) => editSalesTax(tax),
          }}
          title="Manage Sales Tax"
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
