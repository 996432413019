import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { MediaRenditions } from "./MediaRendition";
import { MediaSourceList } from "./MediaSourceList";

export const MediaSourceConfig = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <MediaSourceList />} />
      <Route path={`${url}/rendition`} render={() => <MediaRenditions />} />
    </Switch>
  );
};
