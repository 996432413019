import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addMediaSource,
  deleteMediaSource,
  editMediaSource,
  getAllRawVideos,
} from "../../../queries/media-source";
import { getAllS3Bucket } from "../../../queries/s3bucket";
import { MediaSource, S3BucketType } from "../../../shared/modelTypes";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { reactSelectCustomStyle } from "../../../theme/theme";

export const MediaSourceList = () => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const { isLoading, data: mediaSources = [] } = useQuery(
    "media/media_sources",
    getAllRawVideos,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: s3Buckets = [] } = useQuery<S3BucketType[]>(
    "config/s3_buckets",
    getAllS3Bucket,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  // let buckets = s3Buckets.map((b: { id: any; }) => ({ id: b.id }));

  const { mutateAsync: mutAddMediaSource } = useMutation(addMediaSource, {
    onSuccess: (newSubject) => {
      queryClient.invalidateQueries("media/media_sources");
    },
  });

  const { mutateAsync: mutEditMediaSource } = useMutation(editMediaSource, {
    onSuccess: (newSubject) => {
      queryClient.invalidateQueries("media/media_sources");
    },
  });

  const { mutateAsync: mutDeleteMediaSource } = useMutation(deleteMediaSource, {
    onSuccess: (newSubject) => {
      queryClient.invalidateQueries("media/media_sources");
    },
  });
  const columnDefintion: Column<MediaSource>[] = [
    {
      title: "Title",
      field: "title",
      validate: rowData => Boolean(rowData.title)
    },

    {
      title: "Duration",
      field: "duration",
      type: "numeric",
    },
    {
      title: "Thumbnail Bucket",
      field: "thumbnail_bucket_id",
      lookup: s3Buckets.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: rowData => Boolean(rowData.thumbnail_bucket_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={s3Buckets.map((bucket: { id: any; title: any }) => ({
              value: bucket.id,
              label: bucket.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event:any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Thumbnail File path",
      field: "thumbnail_file_path",
      validate: rowData => Boolean(rowData.thumbnail_file_path)
    },
    {
      title: "Media Bucket",
      field: "media_bucket_id",
      lookup: s3Buckets.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: rowData => Boolean(rowData.media_bucket_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={s3Buckets.map((bucket: { id: any; title: any }) => ({
              value: bucket.id,
              label: bucket.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event:any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Media File path",
      field: "media_file_path",
      validate: rowData => Boolean(rowData.media_file_path)

    },
  ];

  return (
    <MaterialTable
      isLoading={isLoading}
      data={mediaSources}
      title="Media sources"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutAddMediaSource,
        onRowUpdate: (mediaSource) => {
          return mutEditMediaSource({
            id: mediaSource.id,
            payload: mediaSource,
          });
        },
        onRowDelete: (mediaSource) => {
          return mutDeleteMediaSource(mediaSource.id);
        },
      }}
      onRowClick={(event, rowData) => {
        history.push(`/media/media_source/rendition?source_id=${rowData?.id}`);
      }}
    />
  );
};
