import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { getAllSystems } from "../../../../../queries/categories";
import { System } from "../../../../../shared/modelTypes";

export const SystemSelector: FC<{ onSelect: (values: any[]) => void }> = ({
  onSelect,
}) => {
  const { data: systems = [] } = useQuery<System[]>("systems", ()=>getAllSystems(), {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const [selectedSystems, selectSystem] = useState<System[]>([]);
  return (
    <Grid container>
      <Grid item xs>
        <Autocomplete
          onChange={(_, values) => {
            selectSystem(values);
            onSelect(values);
          }}
          filterSelectedOptions
          multiple
          options={systems}
          getOptionLabel={(option) => option.title}
          value={selectedSystems}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Systems"
              placeholder="Systems"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
