import { useQuery } from "react-query";
import { getAllSubjects } from "../../../queries/categories";
import { getAllColleges } from "../../../queries/college";
import { getAllCBME } from "../../../queries/cbme";
import { getAllHomeConfig } from "../../../queries/homeconfig";
import {
  getStudentsCount,
  getAllStudents,
  getAllStudentsList,
} from "../../../queries/student";
import { List } from "@material-ui/core";
export const couponTypes = [
  { value: "new", title: "New" },
  { value: "renewal", title: "Renewal" },
];

export const GetSubjectOptions = () => {
  const { data: coupons = [] } = useQuery("subjects", getAllSubjects, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const options = coupons.map((item: any) => {
    return {
      value: item.id,
      title: `${item.title}(${item.subject_type})`,
    };
  });
  const subjectoption = [{ value: "id", title: "All" }, ...options];
  return subjectoption;
};
export const GetAllColleges = () => {
  const { data: college = [] } = useQuery(
    "students/all-colleges",
    getAllColleges,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const options = college.map((item: any) => {
    return {
      value: item.id,
      name: `${item.name}`,
    };
  });
  const collegeoption = [{ value: "id", name: "All" }, ...options];
  return collegeoption;
};

export const GetAllHomeConfig = () => {
  const { data: homeconfig = { rows: [] } } = useQuery(
    "config/home_config/list",
    getAllHomeConfig,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const options = homeconfig?.rows?.map((item: any) => {
    return {
      value: item.id,
      name: `${item.title}`,
    };
  });
  const collegeoption = [{ value: "id", name: "All" }, ...options];
  return collegeoption;
};
export const GetAllStudents = () => {
  const { data: students = [] } = useQuery(
    ["/students/all-list"],

    async () => await getAllStudentsList(),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const options = students?.map((item: any) => {
    return {
      value: item.id,
      name: `${item.name}${" ("}${item.phone}${")"}`,
      phone: `${item.phone}`,
    };
  });
  const collegeoption = [{ value: "id", name: "All" }, ...options];
  return collegeoption;
};

export const GetAllCBME = () => {
  const { data: cbme = [] } = useQuery(
    ["videos/cbme"],

    async () => await getAllCBME(),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const options = cbme.map((item: any) => {
    return {
      value: item.subject_id,
      name: item.title,
    };
  });
  const cbmeoption = [{ value: "id", name: "All" }, ...options];
  return cbmeoption;
};
