import { MutationFunction } from "react-query";
import { api } from "../../../api";
import { SubjectPricing } from "./types";

export const getSubjectsPricing = () => api.get("/sales/subject_pricing");

export const addSubjectPricing = (pricing: Omit<SubjectPricing, "id">) =>
  api.post(`/sales/subject_pricing`, undefined, pricing);

export const editSubjectPricing: MutationFunction<
  SubjectPricing,
  Partial<SubjectPricing>
> = (pricing: Partial<SubjectPricing>) => {
  const { id, ...rest } = pricing;
  return api.patch(`/sales/subject_pricing/${id}`, undefined, rest);
};

export const deletePricing: MutationFunction<boolean, number> = (id: number) =>
  api.delete(`/sales/subject_pricing/${id}`);
