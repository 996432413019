import { Button, Grid, Input, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import React, { FC, forwardRef } from "react";
import { ReactComponent as PolarisLogo } from "../../assets/images/polaris_logo.svg";

export type LoginFormikFields = {
  username: string;
  password: string;
};

type LoginFormProps = {
  onSubmit: (values: LoginFormikFields) => void;
};

const useLoginFormStyles = makeStyles({
  logoContainer: {
    textAlign: "center",
  },
});

export const LoginForm: FC<LoginFormProps> = forwardRef<any, LoginFormProps>(
  ({ onSubmit, ...rest }, ref) => {
    const styles = useLoginFormStyles();
    const {
      values,
      handleSubmit,
      errors,
      handleChange,
      isSubmitting,
    } = useFormik<LoginFormikFields>({
      initialValues: {
        username: "",
        password: "",
      },
      onSubmit: onSubmit,
    });
    return (
      <div ref={ref}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            spacing={3}
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs="auto" className={styles.logoContainer}>
              <PolarisLogo
                style={{ transform: "scale(2)", marginTop: "1em" }}
                scale={3}
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                disabled={isSubmitting}
                name="username"
                onChange={handleChange}
                value={values.username}
                error={!!errors.username}
                fullWidth
                placeholder="Username"
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                disabled={isSubmitting}
                name="password"
                onChange={handleChange}
                value={values.password}
                error={!!errors.password}
                fullWidth
                placeholder="Password"
                type="password"
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                disabled={isSubmitting}
                color="primary"
                variant="contained"
                type="submit"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
);
