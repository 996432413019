import { MutationFunction } from "react-query";
import { api } from "../api";
import { MediaSource } from "../shared/modelTypes";

export const getAllRawVideos = () => {
  return api.get("media/media_sources");
};

export const createRawVideo: MutationFunction<
  MediaSource,
  Partial<MediaSource>
> = async (raw) => {
  return api.post("raw-videos", undefined, raw);
};

export const deleteRawVideo: MutationFunction<
  MediaSource,
  Partial<MediaSource>
> = async (raw) => {
  return api.delete(`raw-videos/${raw.id}`);
};

export const addMediaSource: MutationFunction<
  MediaSource,
  Partial<MediaSource> | FormData
> = (mediaSource) => api.post("/media/media_sources", undefined, mediaSource);

export const editMediaSource: MutationFunction<
  MediaSource,
  { id: number; payload: Partial<MediaSource> | FormData }
> = ({ id, payload }) =>
  api.patch(`/media/media_sources/${id}`, undefined, payload);

export const deleteMediaSource: MutationFunction<boolean, number> = (id) =>
  api.delete(`/media/media_sources/${id}`);
