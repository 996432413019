import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { NavItem } from "./types";

type CollapsibleMenuProps = {
  title: string;
  menuItems: NavItem[];
  onClose: (event: any) => void;
  className?: any;
};

const useStyles = makeStyles((theme) => ({
  nestedSubMenu: {
    paddingLeft: theme.spacing(4),
  },
}));

export const CollapsibleMenu: FC<CollapsibleMenuProps> = (props) => {
  const styles = useStyles();
  const [isCollapsed, toggleCollapse] = useState(false);
  const { title, menuItems, onClose, className } = props;

  return (
    <>
      <ListItem
        button
        onClick={() => toggleCollapse((a) => !a)}
        className={className}
      >
        <ListItemText primary={title} />
      </ListItem>
      <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menuItems.map((menuItem) => {
            if (menuItem.subRoutes) {
              return (
                <CollapsibleMenu
                  key={menuItem.title}
                  title={menuItem.title}
                  menuItems={menuItem.subRoutes}
                  onClose={onClose}
                  className={styles.nestedSubMenu}
                />
              );
            }
            return (
              <ListItem
                key={menuItem.title}
                button
                component={Link}
                to={menuItem.path || ""}
                onClick={onClose}
                className={styles.nestedSubMenu}
              >
                <ListItemText primary={menuItem.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
