import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import React, { createContext, FC, useMemo, useState } from "react";
import { portalTheme } from "./theme";

type CustomThemeContextType = {
  toggleTheme: () => void;
};

const defaultContext: CustomThemeContextType = {
  toggleTheme: () => {},
};

export const CustomThemeContext = createContext<CustomThemeContextType>(
  defaultContext
);

export const ThemeProvider: FC = ({ children }) => {
  const [isDarkMode, changeTheme] = useState(true);
  const toggleTheme = () => {
    changeTheme(!isDarkMode);
  };
  const theme = useMemo(
    () =>
      createMuiTheme({
        ...portalTheme,
        palette: {
          ...portalTheme.palette,
          type: isDarkMode ? "dark" : "light",
        },
      }),
    [isDarkMode]
  );
  return (
    <CustomThemeContext.Provider
      value={{
        toggleTheme,
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </CustomThemeContext.Provider>
  );
};
