import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";

export default function DeletePasssCode(props: any) {
  return (
    <Dialog onClose={props.handleDeleteClose} open={props.IsDelete}>
      <DialogTitle>Use your passcode</DialogTitle>
      <DialogContent>
        <TextField
          error
          helperText={props.isDeleteError === true ? "Incorrect" : ""}
          autoFocus
          margin="dense"
          id="name"
          label="Passcode"
          type="password"
          fullWidth
          variant="standard"
          value={props.passCode}
          onChange={(event) => props.setDeletePassCode(event.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.handleClose();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.handleDeleteClose}>
          Cancel
        </Button>
        {props.spinner ? (
         <CircularProgress />
        ) : (
          <Button color="primary" onClick={props.DeleteHandlerClick}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
