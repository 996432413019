import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../../api';
import { getInCompleteRegistration } from '../../queries/student';
import { InCompleteRegistrations } from '../../shared/modelTypes';
import { Passcode } from '../Administration/Passcode';

export const InCompleteRegistration = () => {
  const queryClient = useQueryClient();
  const [passCode, setPassCode] = useState<string>('');
  const [show, setShow] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [regId, setRegId] = useState(0);
  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    if (passCode === 'phoenix125') {
      setShow(false);
    }
    setIsError(true);
  };
  const { isLoading, data: inCompleteRegistration = [] } = useQuery(
    'students/incomplete_registration',
    getInCompleteRegistration,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columnDefintion: Column<InCompleteRegistrations>[] = [
    {
      title: 'Name',
      field: 'name',
    },

    {
      title: 'E-mail',
      field: 'email',
    },
    {
      title: 'Phone',
      field: 'phone',
    },
    {
      title: 'Created at',
      field: 'initiated_at',
      type: 'date',
      render: ({ initiated_at }) => {
        return moment(initiated_at)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
  ];

  const showConfirmation = (id: number) => {
    setConfirm(true);
    setRegId(id);
  };

  const markComplete = async () => {
    let response;
    try {
      response = await api.patch(
        `students/close-incomplete-registration/${regId}`,
        undefined,
        {}
      );

      setConfirm(false);
      setRegId(0);
      queryClient.invalidateQueries();
    } catch (errorReq) {
      console.log(errorReq);
    }
    return response;
  };

  return show === false ? (
    <>
      <MaterialTable
        isLoading={isLoading}
        title='In-Complete Registration'
        columns={columnDefintion.map((c) => ({
          ...c,
          tableData: undefined,
        }))}
        data={inCompleteRegistration}
        actions={[
          {
            icon: 'done',
            tooltip: 'Mark as Complete',
            onClick: (event, rowData: any) =>
              showConfirmation(rowData.registration_id),
          },
        ]}
      />
      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure want to mark as complete?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(false)}>Cancel</Button>
          <Button onClick={markComplete}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Passcode
      passCode={passCode}
      isErrors={isError}
      setPassCode={setPassCode}
      handleClose={handleClose}
    />
  );
};
