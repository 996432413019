import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Student } from "./Student";
import { StudentList } from "./StudentList";

export const StudentConfig = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <StudentList />} />
      <Route path={`${url}/profile`} render={() => <Student />} />
    </Switch>
  );
};
