import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { Passcode } from "../Administration/Passcode";
import { ErrorLogTable } from "./ErrorLogTable";
import { LogView } from "./LogView";

export const ErrorLogsView = () => {
  const [errorCode, setErrorCode] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [passCode, setPassCode] = useState<string>("");
  const [show, setShow] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    if (passCode === "phoenix125") {
      setShow(false);
    }
    setIsError(true);
  };
  const routeMatch = useRouteMatch();
  return show === false ? (
    <Switch>
      <Route
        exact
        path={`${routeMatch.url}/:error_code`}
        render={() => <LogView />}
      />
      <Route
        exact
        path={`${routeMatch.url}/:start_date/:end_date`}
        render={() => <ErrorLogTable />}
      />
      <Route
        exact
        path={routeMatch.url}
        render={() => (
          <Grid container direction="column" style={{ padding: "1em" }}>
            <Grid item xs="auto">
              <Typography variant="h6">View Error Logs</Typography>
            </Grid>
            <Grid item xs="auto">
              <Paper style={{ padding: "1em" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs="auto">
                    <Typography>Quick View:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Error Code"
                      variant="outlined"
                      value={errorCode}
                      onChange={(e) => setErrorCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      component={Link}
                      color="primary"
                      variant="contained"
                      to={`${routeMatch.url}/${errorCode}`}
                      disabled={!errorCode}
                    >
                      View Error
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs="auto">
              <Paper style={{ padding: "1em" }}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs>
                    <Typography>Filter Errors Between Dates</Typography>
                  </Grid>
                  <Grid item xs>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          label="Start Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          label="End Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Button
                          color="primary"
                          variant="contained"
                          component={Link}
                          to={`${routeMatch.url}/${startDate}/${endDate}`}
                        >
                          Fetch Errors
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      />
    </Switch>
  ) : (
    <Passcode
      passCode={passCode}
      isErrors={isError}
      setPassCode={setPassCode}
      handleClose={handleClose}
    />
  );
};
