import { MutationFunction } from 'react-query';
import { api } from '../api';
import { College } from '../shared/modelTypes';

export const getAllColleges = () => {
  return api.get('students/all-colleges');
};

export const createCollege: MutationFunction<College, Partial<College>> =
  async (college) => {
    return api.post('students/add-college', undefined, college);
  };

export const updateCollege: MutationFunction<
  College,
  { id: number; payload: Partial<College> }
> = async ({ id, payload }) => {
  return api.patch(`students/edit-college/${id}`, undefined, payload);
};

export const deleteCollege: MutationFunction<College, Partial<College>> =
  async (college) => {
    return api.delete(`students/delete-college/${college.id}`);
  };
