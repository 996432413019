import { MutationFunction } from "react-query";
import { api } from "../api";
import { Coupons } from "../shared/modelTypes";

export const getAllCoupons = () => {
  return api.get("coupons");
};

export const createCoupon: MutationFunction<
  Coupons,
  Partial<Coupons> | FormData
> = (coupon) => {
  return api.post("coupons", undefined, coupon);
};

export const deleteCoupon: MutationFunction<Coupons, Partial<Coupons>> = async (
  coupon
) => {
  return api.delete(`coupons/${coupon.id}`);
};

export const updateCoupon: MutationFunction<
  Coupons,
  { id: number; payload: Partial<Coupons> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`coupons/${id}`, undefined, payload);
};
