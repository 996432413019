import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC } from "react";

type AlertDialogProps = {
  title: string | null | undefined;
  description: string | null | undefined;
  showAlert: boolean | null | undefined;
  onClose: () => void;
};

export const AlertDialog: FC<AlertDialogProps> = (props) => {
  const { title, description, showAlert, onClose } = props;

  if (!title && !description) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={!!showAlert}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
