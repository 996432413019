import React, { FunctionComponent, useContext, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { api } from "../api";
import { AppContext } from "../AppContext";
import { Welcome } from "../components/Welcome";

type PrivateRouteProps = {
  render: FunctionComponent;
  fallbackPath: string;
} & RouteProps;

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  render: Render,
  fallbackPath,
  ...rest
}) => {
  const {
    state: {
      portal: { isAuthenticated },
    },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    async function checkForAuth() {
      if (isAuthenticated === undefined) {
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          console.log(refreshToken);
          const { error, access_token } = await api.post(
            "auth/renew",
            undefined,
            { refresh_token: refreshToken }
          );
          if (error) {
            throw new Error(error);
          }
          api.setAccessToken(access_token);
          dispatch({ type: "AUTH", payload: { isAuthenticated: true } });
        } catch (error) {
          dispatch({ type: "AUTH", payload: { isAuthenticated: false } });
        }
      }
    }

    checkForAuth();
    api.onAuthFailure = () => {
      dispatch({ type: "AUTH", payload: { isAuthenticated: false } });
    };
  }, [isAuthenticated, dispatch]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated === undefined) {
          return <Welcome text="Checking your authentication status..." />;
        }

        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: fallbackPath,
                state: { from: location.pathname },
              }}
            />
          );
        }
        return <Render />;
      }}
    />
  );
};
