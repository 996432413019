import { Column } from "material-table";
import moment from "moment";
import React from "react";
import { reactSelectCustomStyle } from "../../../theme/theme";
import { ColumnConfigArgs, SubjectPricing } from "./types";
import Select from "react-select";

export const getPricingTableColumns = (
  args: ColumnConfigArgs
): Column<SubjectPricing>[] => {
  const { subjects } = args;
  return [
    {
      title: "Subject",
      field: "subject_id",
      lookup: subjects.reduce(
        (acl, curr) => ({ ...acl, [curr.id]: curr.title }),
        {}
      ),
      validate: rowData => Boolean(rowData.subject_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={subjects.map((subject: { id: any; title: any }) => ({
              value: subject.id,
              label: subject.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event:any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Validity of Subscription",
      field: "purchase_validity",
      type: "numeric",
      render: (subject) => <span>{subject.purchase_validity} days</span>,
      validate: rowData => Boolean(rowData.purchase_validity)
    },
    {
      title: "Price",
      field: "base_price",
      type: "currency",
      render: (subject) => (
        <span>
          {new Intl.NumberFormat("en-in", {
            currency: "INR",
            style: "currency",
          }).format(subject.base_price)}
        </span>
      ),
      validate: rowData => Boolean(rowData.base_price)
    },
    {
      title: "Pricing Valid From",
      field: "valid_from",
      type: "date",
      render: (subject) => (
        <span>{moment(subject.valid_from).format("DD MMM YYYY")}</span>
      ),
      validate: rowData => Boolean(rowData.valid_from)
    },
    {
      title: "Pricing Valid Through",
      field: "valid_through",
      type: "date",
      render: (subject) => (
        <span>{moment(subject.valid_through).format("DD MMM YYYY")}</span>
      ),
      validate: rowData => Boolean(rowData.valid_through)
    },
    {
      title: "Renewal discount percentage",
      field: "renewal_discount_percentage",
      type: "numeric",
      render: (subject) => <span>{subject.renewal_discount_percentage} %</span>,
    },
  ];
};
