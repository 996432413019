import { Avatar, Chip } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { MediaSource } from "../../../shared/modelTypes";
import { reactSelectCustomStyle } from "../../../theme/theme";
import { getAllMediaSources } from "../LiveStreams/queries";
import {
  addPremier,
  deletePremier,
  getAllPremieres,
  Premier,
  updatePremier,
} from "./queries";

export const Premieres = () => {
  const queryClient = useQueryClient();

  const { isLoading, data: premieres = [] } = useQuery<Premier[]>(
    "media/premieres",
    getAllPremieres,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: mediaSources = [] } = useQuery<MediaSource[]>(
    "media/sources",
    getAllMediaSources,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync: mutAddPremier } = useMutation(addPremier, {
    onSuccess: () => {
      queryClient.invalidateQueries("media/premieres");
    },
  });

  const { mutateAsync: mutEditPremier } = useMutation(updatePremier, {
    onSuccess: () => {
      queryClient.invalidateQueries("media/premieres");
    },
  });

  const { mutateAsync: mutDeletePremier } = useMutation(deletePremier, {
    onSuccess: () => {
      queryClient.invalidateQueries("media/premieres");
    },
  });
  const columnDefintion: Column<Premier>[] = [
    {
      title: "Title",
      field: "title",
      validate: (rowData) => Boolean(rowData.title),
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Premier Thumbnail",
      field: "thumbnail_blob",
      render: ({ thumbnail_url, title }) => {
        if (!thumbnail_url) {
          return null;
        }
        return <Avatar src={thumbnail_url} alt={`icon_for_${title}`} />;
      },
      editComponent: ({ onChange }) => (
        <input
          type="file"
          onChange={(event) => {
            if (!event.target.files) {
              return;
            }
            onChange(event.target.files[0]);
          }}
        />
      ),
    },
    {
      title: "Source Media",
      field: "media_source_id",
      type: "string",
      lookup: mediaSources.reduce(
        (acl, curr) => ({ ...acl, [curr.id]: curr.title }),
        {}
      ),
      validate: (rowData) => Boolean(rowData.media_source_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={mediaSources.map((media: { id: any; title: any }) => ({
              value: media.id,
              label: media.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Topics List",
      field: "topics_list",
      render: (premier) => (
        <>
          {(premier.topics_list || "").split(",").map((topic) => (
            <Chip key={topic} label={topic} />
          ))}
        </>
      ),
    },
    {
      title: "Available From",
      field: "available_from",
      type: "datetime",
      validate: (rowData) => Boolean(rowData.available_from),
    },
    {
      title: "Available Through",
      field: "available_through",
      type: "datetime",
      validate: (rowData) => Boolean(rowData.available_through),
    },
  ];

  return (
    <MaterialTable
      isLoading={isLoading}
      data={premieres}
      title="Premiers"
      columns={columnDefintion}
      editable={{
        onRowAdd: (premier) => {
          premier.available_from = (premier.available_from as Date).toUTCString();
          premier.available_through = (premier.available_through as Date).toUTCString();
          if (premier.thumbnail_blob) {
            const formData = new FormData();
            formData.append("thumbnail", premier.thumbnail_blob);
            (Object.keys(premier).filter(
              (key) => !["thumbnail_blob", "thumbnail_url"].includes(key)
            ) as Array<
              keyof Omit<Premier, "thumbnail_blob" | "thumbnail_url">
            >).forEach((key) => {
              formData.append(key, String(premier[key]));
            });
            return mutAddPremier(formData);
          }
          return mutAddPremier(premier);
        },
        onRowUpdate: (premier) => {
          if (premier.thumbnail_blob) {
            const formData = new FormData();
            formData.append("thumbnail", premier.thumbnail_blob);
            (Object.keys(premier).filter(
              (key) => !["thumbnail_blob", "thumbnail_url"].includes(key)
            ) as Array<
              keyof Omit<Premier, "thumbnail_blob" | "thumbnail_url">
            >).forEach((key) => {
              formData.append(key, String(premier[key]));
            });
            return mutEditPremier({
              id: premier.id,
              payload: formData,
            });
          }
          return mutEditPremier({
            id: premier.id,
            payload: premier,
          });
        },
        onRowDelete: (premier) => mutDeletePremier(premier.id),
      }}
    />
  );
};
