import { Avatar } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import {
  createSystem,
  deleteSystem,
  getAllSubjects,
  getAllSystems,
  updateSystem,
} from "../../queries/categories";
import { System } from "../../shared/modelTypes";
import Select from "react-select";
import { reactSelectCustomStyle } from "../../theme/theme";

export const Systems = () => {
  const history = useHistory();
  const subjectId = new URLSearchParams(useLocation().search).get("subject_id");
  console.log("in system", subjectId);
  const { isLoading, data: systems = [] } = useQuery(
    ["systems", subjectId],
    () => getAllSystems(subjectId),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();
  // const subjects = queryClient.getQueryData<Subject[]>("subjects") || [];
  const { data: subjects = [] } = useQuery("subjects", getAllSubjects, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const columnDefintion: Column<System>[] = [
    {
      title: "System Title",
      field: "title",
      type: "string",
      validate: (rowData) => Boolean(rowData.title),
    },

    {
      title: "Subject",
      field: "subject_id",
      lookup: subjects.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: (rowData) => Boolean(rowData.subject_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={subjects.map((subject: { id: any; title: any }) => ({
              value: subject.id,
              label: subject.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "System Icon",
      field: "icon_blob",
      render: ({ icon_url, title }) => {
        if (!icon_url) {
          return null;
        }
        return <Avatar src={icon_url} alt={`icon_for_${title}`} />;
      },
      editComponent: ({ onChange }) => (
        <input
          type="file"
          onChange={(event) => {
            console.log(event);
            if (!event.target.files) {
              return;
            }
            onChange(event.target.files[0]);
          }}
        />
      ),
    },
    {
      title: "List Order",
      field: "list_order",
      type: "numeric",
      validate: (rowData) => Boolean(rowData.list_order),
    },
  ];

  const { mutateAsync: mutCreateSystem } = useMutation(createSystem, {
    onSuccess: (newSystem) => {
      queryClient.setQueryData<Partial<System>[]>("systems", (prev) => [
        ...(prev || []),
        newSystem,
      ]);
    },
  });

  const { mutateAsync: mutDeleteSystem } = useMutation(deleteSystem, {
    onSuccess: (params) => {
      queryClient.invalidateQueries("systems");
    },
  });

  const { mutateAsync: mutUpdateSystem } = useMutation(updateSystem, {
    onSuccess: (updatingSystem) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      title="Systems"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      data={systems}
      editable={{
        onRowAdd: (system) => {
          if (system.icon_blob) {
            const formData = new FormData();
            formData.append("icon", system.icon_blob);
            (Object.keys(system).filter(
              (key) => !["icon_blob", "icon_url"].includes(key)
            ) as Array<keyof Omit<System, "icon_blob" | "icon_url">>).forEach(
              (key) => {
                formData.append(key, String(system[key]));
              }
            );
            return mutCreateSystem(formData);
          }
          return mutCreateSystem(system);
        },
        onRowUpdate: (updatedSystem) => {
          if (updatedSystem.icon_blob) {
            const formData = new FormData();
            formData.append("icon", updatedSystem.icon_blob);
            (Object.keys(updatedSystem).filter(
              (key) => !["icon_blob", "icon_url"].includes(key)
            ) as Array<keyof Omit<System, "icon_blob" | "icon_url">>).forEach(
              (key) => {
                formData.append(key, String(updatedSystem[key]));
              }
            );
            return mutUpdateSystem({
              id: updatedSystem.id,
              payload: formData,
            });
          }
          return mutUpdateSystem({
            id: updatedSystem.id,
            payload: updatedSystem,
          });
        },
        onRowDelete: mutDeleteSystem,
      }}
      onRowClick={(event, rowData) => {
        history.push(`/administration/topics?topic_id=${rowData?.id}`);
      }}
    />
  );
};
