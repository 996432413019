import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  CardActions,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

export const Passcode = (props: {
  passCode: string;
  isErrors: boolean;
  setPassCode: (arg0: string) => void;
  handleClose: () => void;
}) => {
  const history = useHistory();

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      style={{
        justifyContent: "center",
        height: "90vh",
      }}
    >
      <Grid item xs={3}>
        <Card elevation={2}>
          <CardContent>
            <Grid
              container
              direction="column"
              spacing={3}
              alignContent="center"
            >
              <Grid item xs="auto">
                <Typography variant="h5" component="div">
                  Use your passcode
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <TextField
                  error
                  helperText={props.isErrors === true ? "Incorrect" : ""}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Passcode"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={props.passCode}
                  onChange={(event) => props.setPassCode(event.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      props.handleClose();
                    }
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions
            disableSpacing
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              color="primary"
              onClick={() => {
                history.push(`/administration/subjects`);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={props.handleClose}>
              Ok
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
