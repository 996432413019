import { MutationFunction } from "react-query";
import { api } from "../api";
import { VideoType, RelativeVideoType } from "../shared/modelTypes";

export const getAllVideos = () => {
  return api.get("media/videos");
};

export const getAllRelativeVideos = (
  videoId: string | null | undefined = undefined
) => {
  return api.get(`videos/related-video/${videoId}`);
};

export const createVideo: MutationFunction<
  VideoType,
  Partial<VideoType> | FormData | any
> = async (video) => {
  video["flash_note_bucket_id"] = video.thumbnail_bucket_id;
  return api.post("media/videos", undefined, video);
};
export const createRelativeVideo: MutationFunction<
  RelativeVideoType,
  Partial<RelativeVideoType> | FormData | any
> = async (video) => {
  console.log(video);
  return api.post("videos/related-video", undefined, video.data);
};
export const updateRelativeVideo: MutationFunction<
  VideoType,
  { id: number; payload: Partial<RelativeVideoType> | FormData | any }
> = async ({ id, payload }) => {
  return api.patch(`videos/related-video/${id}`, undefined, payload);
};
export const deleteRelativeVideo: MutationFunction<
  RelativeVideoType,
  Partial<VideoType>
> = async (video) => {
  return api.delete(`videos/related-video/${video.id}`);
};
export const deleteVideo: MutationFunction<VideoType, Partial<VideoType>> =
  async (video) => {
    return api.delete(`media/videos/${video.id}`);
  };

export const updateVideo: MutationFunction<
  VideoType,
  { id: number; payload: Partial<VideoType> | FormData | any }
> = async ({ id, payload }) => {
  payload["flash_note_bucket_id"] = payload.thumbnail_bucket_id;
  return api.patch(`media/videos/${id}`, undefined, payload);
};
