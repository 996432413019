import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { FC } from "react";

type WelcomeScreenProps = {
  text?: string;
  circularProgressSize?: string | number | undefined;
};
const useWelcomeScreenStyles = makeStyles({
  container: {
    height: "100vh",
  },
});

export const Welcome: FC<WelcomeScreenProps> = ({
  text = `Please wait while we're setting things up...`,
  circularProgressSize = "10em",
}) => {
  const styles = useWelcomeScreenStyles();
  return (
    <Grid
      container
      justify="center"
      className={styles.container}
      alignContent="center"
    >
      <Grid item xs style={{ textAlign: "center" }}>
        <CircularProgress size={circularProgressSize} />
        <Typography variant="h5">{text}</Typography>
      </Grid>
    </Grid>
  );
};
