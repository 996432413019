import { AppBar, Button, Grid, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PolarisLogo } from "../../assets/images/polaris_logo.svg";
import { SideDrawer } from "../SideDrawer";
import { UserMenu } from "./UserMenu";

export const HeaderBar = () => {
  const [showDrawer, toggleDrawer] = useState(false);
  return (
    <AppBar position="static" color="secondary">
      <Toolbar>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs="auto">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer((a) => !a)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <Link to="/">
              <PolarisLogo />
            </Link>
          </Grid>
          <Grid item xs>
            <div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                component={Link}
                to="/admin"
              >
                Administration
              </Button>
            </div>
          </Grid>
          <Grid item xs="auto">
            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
      <SideDrawer
        showDrawer={showDrawer}
        onDrawerClose={() => toggleDrawer((a) => !a)}
      />
    </AppBar>
  );
};
