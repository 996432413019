import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createRelativeVideo,
  deleteRelativeVideo,
  getAllVideos,
  getAllRelativeVideos,
  updateRelativeVideo,
} from "../../queries/video";
import { getAllSubjects } from "../../queries/categories";
import { RelativeVideoType } from "../../shared/modelTypes";
import Select from "react-select";
import { reactSelectCustomStyle } from "../../theme/theme";
import { useRouteMatch } from "react-router-dom";
export const VideoRelative = () => {
  const {
    url,
    params: { video_id },
  } = useRouteMatch<{ video_id: string }>();

  const { data: subjects = [] } = useQuery("subjects", getAllSubjects, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const { data: videos = [] } = useQuery("media/videos", getAllVideos, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const { isLoading, data: relativeVideo = [] } = useQuery<RelativeVideoType[]>(
    ["videos/related-video", video_id],
    () => getAllRelativeVideos(video_id),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();
  const columnDefintion: Column<RelativeVideoType>[] = [
 
    {
      title: "Video",
      field: "integrated_video_id",
      render: (obj) => obj.integrated_video_title,
      lookup: videos.reduce(
        (acl: any, curr: any) => ({ ...acl, [curr.id]: curr.name }),
        {}
      ),
      editComponent: (props: any) => {
        const current = videos
          .filter((item: any) => item.id === props.value)
          .map((item: any) => item.title);

        return (
          <Select
            value={{
              value: props.value,
              label: current,
            }}
            options={videos.map((states: { id: any; title: any }) => ({
              value: states.id,
              label: states.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              props.onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Subject",
      field: "subject_id",
      render: (obj) => obj.subject_title,
      lookup: subjects.reduce(
        (acl: any, curr: any) => ({ ...acl, [curr.id]: curr.name }),
        {}
      ),
      editComponent: (props: any) => {
        const current = subjects
          .filter((item: any) => item.id === props.value)
          .map((item: any) => item.title);

        return (
          <Select
            value={{
              value: props.value,
              label: current,
            }}
            options={subjects.map((states: { id: any; title: any }) => ({
              value: states.id,
              label: states.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              props.onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
 
    {
      title: "Is free?",
      field: "is_integrated_free",
      type: "boolean",
      initialEditValue: true,
    },

    {
      title: "List order",
      field: "display_order",
      validate: (rowData) => Boolean(rowData.display_order),
      type: "numeric",
    },
  ];

  const { mutateAsync: mutCreateVideo } = useMutation(createRelativeVideo, {
    onSuccess: (newSystem) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutDeleteVideo } = useMutation(deleteRelativeVideo, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateVideo } = useMutation(updateRelativeVideo, {
    onSuccess: (updatingSystem) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      data={relativeVideo}
      title={"Integrated Video"}
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: (addVideo) =>
          mutCreateVideo({
            data: {
              ...addVideo,
              video_id: video_id,
            },
          }),
        onRowUpdate: (updatingVideo) =>
          mutUpdateVideo({
            id: updatingVideo.id,
            payload: updatingVideo,
          }),
        onRowDelete: mutDeleteVideo,
      }}
    />
  );
};
