import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { getAllTopics } from "../../../../../queries/categories";
import { Topic } from "../../../../../shared/modelTypes";

export const TopicSelector: FC<{ onSelect: (values: any[]) => void }> = ({
  onSelect,
}) => {
  const { data: topics = [] } = useQuery<Topic[]>(
    "topics",
    () => getAllTopics(),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const [selectedTopics, selectTopic] = useState<Topic[]>([]);
  return (
    <Grid container>
      <Grid item xs>
        <Autocomplete
          onChange={(_, values) => {
            selectTopic(values);
            onSelect(values);
          }}
          filterSelectedOptions
          multiple
          options={topics}
          getOptionLabel={(option) => option.title}
          value={selectedTopics}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Topics"
              placeholder="Topics"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
