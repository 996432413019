import { MutationFunction } from "react-query";
import { api } from "../../api";
import { StudentProfile } from "../Student/Student";
import { ApiErrorLog } from "../typings/audit/ApiErrorLog";

export const getAllErrorLogs = (startDate: string, endDate: string) => {
  console.log(startDate);
  return api.get("logs/errors", { start_date: startDate, end_date: endDate });
};

export const getErrorLog = (errorCode: string) =>
  api.get(`logs/errors`, { error_code: errorCode });

export const deleteErrorLog: MutationFunction<
  ApiErrorLog<StudentProfile>,
  Partial<ApiErrorLog<StudentProfile>>
> = async (log) => {
  return api.delete(`logs/${log.id}`);
};
