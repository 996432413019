import MaterialTable, { Column } from "material-table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { State } from "../../../shared/modelTypes";
import * as React from "react";
import {
  createState,
  deleteState,
  getAllState,
  updateState,
} from "../../../queries/state";

export const States = () => {
  const { isLoading, data: state = [] } = useQuery(
    "students/states",
    getAllState,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const columnDefintion: Column<State>[] = [
    {
      title: "State",
      field: "name",
    },
  ];

  const queryClient = useQueryClient();

  const { mutateAsync: mutCreateState } = useMutation(createState, {
    onSuccess: (newState) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutDeleteState } = useMutation(deleteState, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateState } = useMutation(updateState, {
    onSuccess: (updatedState) => {
      queryClient.invalidateQueries();
    },
  });
  return (
    <MaterialTable
      isLoading={isLoading}
      data={state}
      title="State"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutCreateState,
        onRowUpdate: (updatingBucket) =>
          mutUpdateState({
            id: updatingBucket.id,
            payload: updatingBucket,
          }),
        onRowDelete: mutDeleteState,
      }}
    />
  );
};
