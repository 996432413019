import { MutationFunction } from "react-query";
import { api } from "../api";
import { Banner } from "../shared/modelTypes";

export const getAllBanners = () => {
  return api.get("config/banner_config");
};

export const createBanner: MutationFunction<
  Banner,
  Partial<Banner> | FormData
> = (banner) => {
  console.log('banner',banner)
  return api.post("config/banner_config", undefined, banner);
};

export const deleteBanner: MutationFunction<Banner, Partial<Banner>> = async (
  banner
) => {
  return api.delete(`config/banner_config/${banner.id}`);
};

export const updateBanner: MutationFunction<
  Banner,
  { id: number; payload: Partial<Banner> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`config/banner_config/${id}`, undefined, payload);
};
