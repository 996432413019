import { Column } from 'material-table';
import moment from 'moment';
import { Coupons } from '../../../shared/modelTypes';
import { couponTypes, GetSubjectOptions } from './formOptions';

export const getColumnConfig = (): Column<Coupons>[] => {
  const subjects = GetSubjectOptions();
  return [
    {
      title: 'Title',
      field: 'title',
      type: 'string',
    },
    {
      title: 'Description',
      field: 'description',
      type: 'string',
    },
    {
      title: 'Coupon Code',
      field: 'coupon_code',
      type: 'string',
    },
    {
      title: 'Valid From',
      field: 'valid_from',
      type: 'datetime',
      initialEditValue: moment().startOf('minute'),
      render: ({ valid_from }) => {
        return moment(valid_from)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
    {
      title: 'Valid Through',
      field: 'valid_through',
      type: 'datetime',
      initialEditValue: moment().endOf('hour'),
      render: ({ valid_through }) => {
        return moment(valid_through)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
    {
      title: 'Discount Percentage(%)',
      field: 'discount_percentage',
      type: 'numeric',
    },
    {
      title: 'Maximum Discunt(%)',
      field: 'max_discount',
      type: 'numeric',
    },

    {
      title: 'Suspended',
      field: 'is_suspended',
      type: 'boolean',
    },
    {
      title: 'User Max. Limit',
      field: 'user_max_limit',
      type: 'numeric',
    },
    {
      title: 'Global Max. Limit',
      field: 'global_max_limit',
      type: 'numeric',
    },
    {
      title: 'Applicable Subjects',
      field: 'applicable_subjects',
      lookup: subjects.reduce(
        (acl: any, curr: { value: any; title: any }) => ({
          ...acl,
          [curr.value]: curr.title,
        }),
        {}
      ),
    },
    {
      title: 'Coupon type',
      field: 'coupon_type',
      lookup: couponTypes.reduce(
        (acl: any, curr: { value: any; title: any }) => ({
          ...acl,
          [curr.value]: curr.title,
        }),
        {}
      ),
    },
    {
      title: 'Redeem Count',
      field: 'globalcount',
    },
  ];
};
