import { Avatar } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import {
  createTopic,
  deleteTopic,
  getAllSystems,
  getAllTopics,
  updateTopic,
} from "../../queries/categories";
import { Topic } from "../../shared/modelTypes";
import { reactSelectCustomStyle } from "../../theme/theme";
import Select from "react-select";

export const Topics = () => {
  const topicId = new URLSearchParams(useLocation().search).get("topic_id");

  const { isLoading, data: topics = [] } = useQuery<Topic[]>(
    ["topics", topicId],
    () => getAllTopics(topicId),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();
  const systemtId = new URLSearchParams(useLocation().search).get("system_id");

  const { data: systems = [] } = useQuery(
    ["systems", systemtId],
    () => getAllSystems(systemtId),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const columnDefintion: Column<Topic>[] = [
    {
      title: "System",
      field: "system_id",
      lookup: systems.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: (rowData) => Boolean(rowData.system_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={systems.map((system: { id: any; title: any }) => ({
              value: system.id,
              label: system.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },

    {
      title: "Title",
      field: "title",
      validate: (rowData) => Boolean(rowData.title),
    },
    {
      title: "Topic Icon",
      field: "icon_blob",
      render: ({ icon_url, title }) => {
        if (!icon_url) {
          return null;
        }
        return <Avatar src={icon_url} alt={`icon_for_${title}`} />;
      },
      editComponent: ({ onChange }) => (
        <input
          type="file"
          onChange={(event) => {
            console.log(event);
            if (!event.target.files) {
              return;
            }
            onChange(event.target.files[0]);
          }}
        />
      ),
    },
    {
      title: "List Order",
      field: "list_order",
      validate: (rowData) => Boolean(rowData.list_order),
    },
  ];
  const { mutateAsync: mutCreateTopic } = useMutation(createTopic, {
    onSuccess: (newSystem) => {
      queryClient.setQueryData<Partial<Topic>[]>("topics", (prev) => [
        ...(prev || []),
        newSystem,
      ]);
    },
  });

  const { mutateAsync: mutDeleteTopic } = useMutation(deleteTopic, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateTopic } = useMutation(updateTopic, {
    onSuccess: (updatingTopic) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      title="Topics"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      data={topics}
      editable={{
        onRowAdd: (topic) => {
          const santizedTopic = {
            ...topic,
            // parent_topic_id:
            //   topic.parent_topic_id === "null" ? null : topic.parent_topic_id,
          };

          if (topic.icon_blob) {
            const formData = new FormData();
            formData.append("icon", topic.icon_blob);
            (Object.keys(santizedTopic).filter(
              (key) => !["icon_blob", "icon_url"].includes(key)
            ) as Array<keyof Omit<Topic, "icon_blob" | "icon_url">>).forEach(
              (key) => {
                formData.append(key, String(topic[key]));
              }
            );
            return mutCreateTopic(formData);
          }

          return mutCreateTopic(santizedTopic);
        },
        onRowUpdate: (updatedTopic) => {
          const santizedTopic = {
            ...updatedTopic,
            // parent_topic_id:
            //   updatedTopic.parent_topic_id === "null"
            //     ? null
            //     : updatedTopic.parent_topic_id,
          };
          if (santizedTopic.icon_blob) {
            const formData = new FormData();
            formData.append("icon", santizedTopic.icon_blob);
            (Object.keys(santizedTopic).filter(
              (key) => !["icon_blob", "icon_url"].includes(key)
            ) as Array<keyof Omit<Topic, "icon_blob" | "icon_url">>).forEach(
              (key) => {
                formData.append(key, String(santizedTopic[key]));
              }
            );
            return mutUpdateTopic({
              id: santizedTopic.id,
              payload: formData,
            });
          }
          return mutUpdateTopic({
            id: santizedTopic.id,
            payload: santizedTopic,
          });
        },
        onRowDelete: mutDeleteTopic,
      }}
    />
  );
};
