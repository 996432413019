import { Reducer } from "react";
import { AppAction } from "./AppActionType";
import { AppState } from "./AppStateType";

export const appReducer: Reducer<AppState, AppAction> = (state, action) => {
  switch (action.type) {
    case "AUTH": {
      return {
        ...state,
        portal: { isAuthenticated: action.payload.isAuthenticated },
      };
    }

    case "show_snack": {
      return {
        ...state,
        portal: { ...state.portal, snack: action.payload },
      };
    }

    case "show_alert": {
      return {
        ...state,
        portal: { ...state.portal, alert: action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
