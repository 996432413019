import {
  Grid,
  Typography,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  updateStudentStatus,
  updateStudentIDcard,
  updateStudentTrail,
} from "../../queries/student";

export const StudentProfile = (props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) => {
  const { children, value, index, ...other } = props;
  console.log("children.is_trial", children);

  const [isDisabled, setIsDisabled] = useState(children.is_disabled);
  const [isTrial, setIsTrial] = useState(children.is_trial);
  const [isidcardverified, setIsIdcardVerified] = useState(false);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const onChangeIsDisabled = () => {
    setIsDisabled(!isDisabled);
    let statusObject = {
      is_disabled: !isDisabled,
    };
    mutUpdateStudentStatus({
      id: children.id,
      payload: statusObject,
    });
  };

  const onChangeIsTrial = () => {
    setIsTrial(!isTrial);
    console.log("children", children);
    let statusObject = {
      isTrial: !isTrial ? "true" : "false",
      studentId: children.id,
    };
    console.log(statusObject);
    mutUpdateStudentTrail({
      id: children.id,
      payload: statusObject,
    });
  };
  useEffect(() => {
    setIsIdcardVerified(children.is_idcard_verified);
  }, [children.is_idcard_verified]);

  useEffect(() => {
    console.log("hereeeeeeee");
    setIsTrial(children.is_trial);
  }, [children.is_trial]);
  const onChangeIsIdcardVerified = () => {
    setIsIdcardVerified(!isidcardverified);
    let statusObject = {
      status: !isidcardverified,
    };
    mutUpdateStudentIDcard({
      id: children.id,
      payload: statusObject,
    });
  };

  const { mutateAsync: mutUpdateStudentIDcard } = useMutation(
    updateStudentIDcard,
    {
      onSuccess: (updatedStatus) => {
        queryClient.invalidateQueries();
      },
    }
  );
  const { mutateAsync: mutUpdateStudentStatus } = useMutation(
    updateStudentStatus,
    {
      onSuccess: (updatedStatus) => {
        queryClient.invalidateQueries();
      },
    }
  );
  const { mutateAsync: mutUpdateStudentTrail } = useMutation(
    updateStudentTrail,
    {
      onSuccess: (updatedStatus) => {
        queryClient.invalidateQueries();
      },
    }
  );
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Grid
            container
            spacing={3}
            alignItems="center"
            style={{ padding: "1em" }}
          >
            <Grid item xs={10}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                Full Name
              </Typography>
              <Typography>{children.full_name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Grid container style={{ flexDirection: "row" }}>
                <Typography style={{ marginTop: 4 }}>Status</Typography>
                <Switch
                  checked={!isDisabled}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  onChange={onChangeIsDisabled}
                />{" "}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                E-mail
              </Typography>
              <Typography>{children.email}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                Mobile
              </Typography>
              <Typography>{children.phone}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Grid container style={{ flexDirection: "row" }}>
                <Typography style={{ marginTop: 4 }}>Trial</Typography>
                <Switch
                  checked={isTrial ? true : false}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  onChange={onChangeIsTrial}
                />{" "}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                College
              </Typography>
              <Typography>{children.college_name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                Year of Study
              </Typography>
              <Typography>{children.year_of_study}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                mbbs/md
              </Typography>
              <Typography>{children?.mbbs_md?.toString()}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                Home country
              </Typography>
              <Typography>{children.country}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
              Studying country
              </Typography>
              <Typography>{children.college_country}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ fontSize: 12, color: "#a1a5a1" }}>
                College ID
              </Typography>
              <Typography>
                <img
                  src={children.id_card_path}
                  alt="College ID"
                  style={{ maxWidth: 200, height: "auto", cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container style={{ flexDirection: "row" }}>
              <Typography style={{ marginLeft: 14 }}>
                College ID Approved
              </Typography>
              <Switch
                checked={isidcardverified}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={onChangeIsIdcardVerified}
              />{" "}
            </Grid>
          </Grid>
          <Dialog
            fullScreen
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle id="customized-dialog-title">
              College ID
              <IconButton
                aria-label="close"
                style={{
                  position: "absolute",
                  right: 1,
                  top: 5,
                  color: "#cccccc",
                }}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <img
                src={children.id_card_path}
                alt="College ID"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};
