import { MutationFunction } from "react-query";
import { api } from "../api";
import { AppReleaseVersion } from "../shared/modelTypes";

export const getAllAppReleaseVersion = () => {
  return api.get("config/app_release_version");
};

export const createAppReleaseVersion: MutationFunction<
  AppReleaseVersion,
  Partial<AppReleaseVersion>
> = async (releaseVersion) => {
  return api.post("config/app_release_version", undefined, releaseVersion);
};

export const updateAppReleaseVersion: MutationFunction<
  AppReleaseVersion,
  { id: number; payload: Partial<AppReleaseVersion> }
> = async ({ id, payload }) => {
  return api.patch(`config/app_release_version/${id}`, undefined, payload);
};

export const deleteAppReleaseVersion: MutationFunction<
  AppReleaseVersion,
  Partial<AppReleaseVersion>
> = async (releaseVersion) => {
  return api.delete(`config/app_release_version/${releaseVersion.id}`);
};
