import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
  deleteCoupon,
  getAllCoupons,
  // updateCoupon,
} from "../../../queries/coupons";
import { getColumnConfig } from "./getColumnConfiguration";

export const CouponsList = () => {
  const { url } = useRouteMatch();
  const queryClient = useQueryClient();
  const columnConfig = getColumnConfig();
  const history = useHistory();

  const { isLoading, data: coupons = [] } = useQuery("coupon", getAllCoupons, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const onEditCoupon = (rowData: any) => {
    delete rowData['globalcount'];
    history.push(`${url}/edit`, rowData);
  };

  const { mutateAsync: mutDeleteCoupon } = useMutation(deleteCoupon, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} style={{ padding: "2em" }} direction="column">
        <Grid item xs>
          <Typography variant="h6">Coupon Configuration</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`${url}/new`}
          >
            Add Coupon
          </Button>
        </Grid>
        <Grid item xs>
          <MaterialTable
            isLoading={isLoading}
            title="Coupons"
            columns={columnConfig}
            data={coupons}
            editable={{
              onRowDelete: mutDeleteCoupon,
              // onRowUpdate: (coupon) =>
              //   mutUpdateCoupon({ id: coupon.id, payload: coupon }),
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit data",
                onClick: (event, rowData: any) => {
                  delete rowData.tableData;
                  onEditCoupon(rowData);
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
