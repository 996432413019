import { Button, Grid, Typography } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import moment from "moment";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useRouteMatch } from "react-router-dom";
import { deleteBanner, getAllBanners } from "../../../queries/banner";
import { Banner } from "../../../shared/modelTypes";
import { Avatar } from "@material-ui/core";

export const BannerList = () => {
  const queryClient = useQueryClient();
  const { url } = useRouteMatch();

  const columnDefintion: Column<Banner>[] = [
    {
      title: "Title",
      field: "title",
      type: "string",
    },
    {
      title: "Banner Icon",
      field: "bannerImage",
      render: ({ banner_url, title }) => {
        if (!banner_url) {
          return null;
        }
        return <Avatar src={banner_url} alt={`icon_for_${title}`} />;
      },
    }, 
    {
      title: "Type",
      field: "item_type",
      type: "string",
    },
    {
      title: "Valid From",
      field: "valid_from",
      type: "datetime",
      initialEditValue: moment().startOf("minute"),
      render: ({ valid_from }) => {
        return moment(valid_from)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
    {
      title: "Valid Through",
      field: "valid_through",
      type: "datetime",
      initialEditValue: moment().startOf("minute"),
      render: ({ valid_through }) => {
        return moment(valid_through)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
    {
      title: "Description",
      field: "description",
      type: "string",
    },
  ];

  const { isLoading, data: banners = [] } = useQuery(
    "config/banner_config",
    getAllBanners,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync: mutDeleteBanner } = useMutation(deleteBanner, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          padding: "1em",
        }}
      >
        <Grid item xs={10}>
          <Typography variant="h4">App Banner Configuration Detail</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`${url}/new`}
          >
            Add Banner
          </Button>
        </Grid>{" "}
        {/* </Grid> */}
      </Grid>
      <Grid item xs>
        <MaterialTable
          isLoading={isLoading}
          title="App Banner"
          columns={columnDefintion}
          data={banners}
          editable={{
            onRowDelete: mutDeleteBanner,
          }}
        />
      </Grid>{" "}
    </>
  );
};
