import * as React from "react";
import MaterialTable, { Column } from "material-table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllS3Bucket,
  createS3Bucket,
  updateS3Bucket,
  deleteS3Bucket,
} from "../../../queries/s3bucket";
import { S3BucketType } from "../../../shared/modelTypes";

export const S3Bucket = () => {
  const { isLoading, data: s3buckets = [] } = useQuery(
    "config/s3_buckets",
    getAllS3Bucket,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const columnDefintion: Column<S3BucketType>[] = [
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Description",
      field: "description",
    },

    {
      title: "Bucket Name",
      field: "bucket_name",
    },
    {
      title: "Cloudfront url",
      field: "cloudfront_url",
    },
    {
      title: "Is enabled?",
      field: "is_enabled",
      type: "boolean",
      initialEditValue: true,
    },
  ];

  const queryClient = useQueryClient();

  const { mutateAsync: mutCreateSubject } = useMutation(createS3Bucket, {
    onSuccess: (newBucket) => {
      queryClient.setQueryData<Partial<S3BucketType>[]>(
        "config/s3_buckets",
        (prev) => [...(prev || []), newBucket]
      );
    },
  });

  const { mutateAsync: mutDeleteS3Bucket } = useMutation(deleteS3Bucket, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateS3Bucket } = useMutation(updateS3Bucket, {
    onSuccess: (updatedBucket) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      data={s3buckets}
      title="S3 Buckets"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutCreateSubject,
        onRowUpdate: (updatingBucket) =>
          mutUpdateS3Bucket({
            id: updatingBucket.id,
            payload: updatingBucket,
          }),
        onRowDelete: mutDeleteS3Bucket,
      }}
    />
  );
};
