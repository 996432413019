import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { createBanner } from "../../../queries/banner";
import {
  getAllSubjects,
  getAllSystems,
  getAllTopics,
} from "../../../queries/categories";
import { getAllVideos } from "../../../queries/video";
import { Banner, Topic } from "../../../shared/modelTypes";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { GetAllHomeConfig } from "../coupons/formOptions";
const itemTypeOptions = [
  // { value: "video", label: "Video" },
  // { value: "subject", label: "Subject" },
  // { value: "topic", label: "Topic" },
  // { value: "system", label: "System" },
  { value: "promotion", label: "Home Slider" },
  { value: "slider_subscription", label: "Subscription" },
];

export const AddBanner = () => {
  const queryClient = useQueryClient();

  const HomeConfigOptions = GetAllHomeConfig();
  const [itemsOption, setItemsOption] = useState<any[]>([]);
  const history = useHistory();
  const [selectedCollege, setSelectedCollege] = useState([
    { value: "", name: "" },
  ]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { mutateAsync: mutCreateBanner } = useMutation(createBanner, {
    onSuccess: (newBanner) => {
      queryClient.setQueryData<Partial<Banner>[]>(
        "config/banner_config",
        (prev) => [...(prev || []), newBanner]
      );
      history.goBack();
    },
  });

  const formik = useFormik<Banner>({
    initialValues: {
      is_enabled: true,
      valid_from: new Date(),
      valid_through: new Date(),
    } as Banner,
    onSubmit: async (values) => {
      const payload = { ...values };
      // if (values.item_payload) {
      //   //@ts-ignore
      //   payload.item_payload = JSON.stringify({ id: values.item_payload.id });
      // }
      //@ts-ignore
      payload.valid_from = (values.valid_from as moment).toISOString();
      //@ts-ignore
      payload.valid_through = (values.valid_through as moment).toISOString();
      console.log("values.home_config", values.home_config);
      values.home_config.find((element) => {
        if (element === "id") {
          values.home_config = [];
          HomeConfigOptions.map((id) => {
            if (id.value != "id") {
              values.home_config.push(id.value);
            }
          });
        }
      });
      var collegesList = values.home_config?.filter(function (e) {
        return e !== "empty";
      });
      payload.home_config = collegesList;

      console.log("payload", payload);
      if (payload.bannerImage) {
        const formData = new FormData();
        formData.append("bannerImage", payload.bannerImage);
        (
          Object.keys(payload).filter(
            (key) => !["bannerImage"].includes(key)
          ) as Array<keyof Omit<Banner, "bannerImage" | "id">>
        ).forEach((key) => {
          if (key !== "home_config") {
            formData.append(key, String(payload[key]));
          } else {
            formData.append(key, JSON.stringify(payload[key]));
          }
        });
        console.log("formData", formData);
        return mutCreateBanner(formData);
      } else {
        alert("Please fill all the details");
      }
    },
  });

  const { handleChange, values, setFieldValue, handleSubmit } = formik;

  const { data: subjects = [] } = useQuery("subjects", getAllSubjects, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const { data: videos = [] } = useQuery("media/videos", getAllVideos, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const { data: topics = [] } = useQuery<Topic[]>(
    ["topics", undefined],
    () => getAllTopics(undefined),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const { data: systems = [] } = useQuery(
    ["systems", undefined],
    () => getAllSystems(undefined),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeType = (type: any) => {
    setFieldValue("item_type", type);
    if (type === "subject") {
      setItemsOption(subjects);
    } else if (type === "video") {
      setItemsOption(videos);
    } else if (type === "topic") {
      setItemsOption(topics);
    } else if (type === "system") {
      setItemsOption(systems);
    } else {
      setItemsOption([]);
    }
  };
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={2} style={{ padding: "2em" }}>
          <Grid item xs={12}>
            <Typography variant="h5">Banner</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>{" "}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="title"
              label="Title"
              value={values.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={HomeConfigOptions}
              disableCloseOnSelect
              value={
                values.home_config
                  ? HomeConfigOptions.filter((item: any) =>
                      values.home_config.includes(item.value)
                    )
                  : []
              }
              onChange={(_, value) => {
                setFieldValue(
                  "home_config",
                  value.map(({ value }: any) => value)
                );
                setSelectedCollege(value);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(option, state) => {
                const selectCollegeIndex = selectedCollege.findIndex(
                  (subject) => subject.name.toLowerCase() === "all"
                );
                if (selectCollegeIndex > -1) {
                  state.selected = true;
                }
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={state.selected}
                    />
                    {option.name}
                  </React.Fragment>
                );
              }}
              renderInput={(params) => {
                const { InputProps, ...restParams } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                  <TextField
                    {...restParams}
                    label={"Home configuration"}
                    variant="outlined"
                    InputProps={{
                      ...restInputProps,
                      startAdornment: (
                        <div
                          id=""
                          style={{
                            overflow: "scroll",
                            height: "150px",
                            overflowX: "hidden",
                            width: "500px",
                          }}
                        >
                          {startAdornment}
                        </div>
                      ),
                    }}
                  />
                );
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={itemTypeOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Home Configuration"
                  variant="outlined"
                />
              )}
              onChange={(_, v) => {
                handleChangeType(v?.value);
              }}
              value={itemTypeOptions.find(
                ({ value }) => value === values.item_type
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Autocomplete
              options={itemsOption}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label="Items" variant="outlined" />
              )}
              onChange={(_, v) => {
                setFieldValue("item_payload", v);
              }}
              value={itemsOption.find(
                ({ value }) => value === values.item_payload
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="display_order"
              label="Display order"
              value={values.display_order}
              type="number"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker
              fullWidth
              format="DD/MM/yyyy"
              label="Valid From"
              views={["year", "month", "date"]}
              value={values.valid_from}
              name="valid_from"
              onChange={(date) => {
                setFieldValue("valid_from", date?.startOf("date"));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker
              fullWidth
              format="DD/MM/yyyy"
              label="Valid Through"
              views={["year", "month", "date"]}
              onChange={(date) =>
                setFieldValue("valid_through", date?.endOf("date"))
              }
              value={values.valid_through}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <p>
              Upload Banner Image{" "}
              <input
                type="file" //bannerImage
                onChange={(event) => {
                  if (!event.target.files) {
                    return;
                  }
                  setFieldValue("bannerImage", event.target.files[0]);
                }}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={true}
                  onChange={handleChange}
                  name="is_enabled"
                  color="primary"
                  value={values.is_enabled}
                />
              }
              label="Enable"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              rowsMax={10}
              variant="outlined"
              name="description"
              label="Description"
              value={values.description}
              onChange={(value) =>
                setFieldValue("description", value.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            {" "}
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>{" "}
            <Button color="primary" variant="contained">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};
