import { TablePagination, TextField, IconButton } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React, { useEffect, useState, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteStudent,
  getAllStudents,
  getStudentsCount,
} from "../../queries/student";
import { Student } from "../../shared/modelTypes";
import { Passcode } from "../Administration/Passcode";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import ExportDailogbox from "./ExportDailogbox";
import { BiDownload, BiTrashAlt } from "react-icons/bi";
import DeletePasssCode from "./DeletePasssCode";
import { SearchOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

export const StudentList = () => {
  const [passCode, setPassCode] = useState<string>("");
  const [deletePassCode, setDeletePassCode] = useState<string>("");
  const [show, setShow] = useState(true);
  const [spinner, SetSpinner] = useState(false);
  const [page, setPage] = useState(0);
  const [countset, setCount] = useState();
  const [searchkey, SetSearchTearm] = useState("");
  const [studentList, SetStudentList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [studenTotalList, SetstudenTotalList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isSearchLoader, setSearchLoader] = useState(false);
  const [isDeleteErrors, setIsDeleteErrors] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const [deleteData, setDeleteData] = useState({});
  const handleClose = () => {
    if (passCode === "phoenix125") {
      setShow(false);
    }
    setIsError(true);
    setPassCode("");
  };

  useEffect(() => {
    FetechData(rowsPerPage, page, searchkey);
  }, []);

  const FetechData = async (row: any, page: any, search: any) => {
    setLoading(true);
    let students = await getAllStudents(row, page + 1, search);
    if (await students) {
      console.log("students", students);
      SetStudentList(await students.data);
      setCount(await students.total_count);
      setLoading(false);
    } else {
    }
  };

  // const { isLoading, data: students = [] } = useQuery(
  //   ["students/all", page, rowsPerPage],

  //   async () => await getAllStudents(rowsPerPage, page + 1),
  //   {
  //     staleTime: 60000,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // useEffect(() => {
  //   setCount(count?.count);
  //   SetStudentList(students);
  // }, [Object.entries(students).length, count]);

  const columnDefintion: Column<Student>[] = [
    {
      title: "Name",
      field: "full_name",
    },

    {
      title: "E-mail",
      field: "email",
    },
    {
      title: "Phone",
      field: "phone",
    },
    {
      title: "College Name",
      field: "college_name",
    },
    {
      title: "Joining Date",
      field: "created_at",
      render: ({ created_at }) => {
        return moment(created_at)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },

    {
      title: "Year",
      field: "year_of_study",
    },
    {
      title: "State",
      field: "state",
    },
    {
      title: "Device Platform",
      field: "last_device_platform",
    },

    {
      title: "App Version",
      field: "app_version",
    },
    {
      title: "Last Used Time",
      field: "last_used_time",
      render: ({ last_used_time }) => {
        return moment(last_used_time)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },
    {
      title: "Total watch History",
      field: "total_duration",
      render: ({ total_duration }) => {
        var a = total_duration.split(":");
        // Hours are worth 60 minutes.
        var minutes = +a[0] * 60 + +a[1];
        var min = minutes + " min";
        return min;
      },
    },
    {
      title: "Account Status",
      field: "is_disabled",
      render: ({ is_disabled }) => {
        if (is_disabled) {
          return "Deactive";
        } else {
          return "Active";
        }
      },
    },
  ];
  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const { mutateAsync: mutDeleteStudent } = useMutation(deleteStudent, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const handleClicksearch = (e: any) => {
    SetSearchTearm(e.target.value);
  };

  const TotalstudentData = async (data: any) => {
    if (data === "close") {
      FetechData(5, 0, "");
    } else {
      FetechData(5, 0, searchkey.toString());
    }
  };
  // TotalstudentData();
  const onCloseHandler = () => {
    console.log("here");
    SetSearchTearm("");
    TotalstudentData("close");
  };
  const handleDeleteClose = () => {
    setIsDelete(false);
  };
  const DeleteHandlerClick = async () => {
    SetSpinner(true);
    if (deletePassCode === "phoenix125") {
      let resutl = mutDeleteStudent(deleteData);

      if (await resutl) {
        let resutl = getAllStudents();
        SetStudentList(await resutl);
        SetSpinner(false);
      }
    } else {
      setIsDeleteErrors(true);
    }
    setIsDelete(false);
    setDeletePassCode("");
  };

  return show === false ? (
    <>
      <>
        <ExportDailogbox
          title=" Export Student List"
          isOpen={isDialogOpen}
          onClose={handleDialogClose}
        ></ExportDailogbox>
      </>

      <DeletePasssCode
        IsDelete={isDelete}
        handleDeleteClose={handleDeleteClose}
        DeleteHandlerClick={DeleteHandlerClick}
        setDeletePassCode={setDeletePassCode}
        isDeleteError={isDeleteErrors}
        spinner={spinner}
      />
      <MaterialTable
        isLoading={isSearchLoader ? isSearchLoader : isLoading}
        title="Users"
        options={{
          search: false,
        }}
        columns={columnDefintion.map((c) => ({
          ...c,
          tableData: undefined,
        }))}
        data={studentList}
        editable={{}}
        actions={[
          {
            icon: () => (
              <TextField
                fullWidth
                id="standard-bare"
                variant="standard"
                value={searchkey}
                placeholder="Search"
                onChange={handleClicksearch}
                InputProps={{
                  startAdornment: (
                    <IconButton style={{ backgroundColor: "transparent" }}>
                      <SearchOutlined
                        onClick={() => TotalstudentData("data")}
                      />
                    </IconButton>
                  ),
                  endAdornment: (
                    <IconButton style={{ backgroundColor: "transparent" }}>
                      <CloseIcon onClick={() => onCloseHandler()} />
                    </IconButton>
                  ),
                }}
              />
            ),
            tooltip: "Search",
            onClick: () => {},
            isFreeAction: true,
          },
          {
            icon: () => <BiDownload />,
            tooltip: "Export to Excel",
            onClick: () => handleClickOpen(),
            isFreeAction: true,
          },
          {
            icon: () => <BiTrashAlt />,
            onClick: (event, rowData: any) => {
              setDeleteData(rowData);
              setIsDelete(true);
            },
          },
        ]}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[5, 10, 20]}
              rowsPerPage={rowsPerPage}
              count={countset}
              page={page}
              onChangePage={(e, incomingPage) => {
                setPage(incomingPage);
                FetechData(rowsPerPage, incomingPage, searchkey);
              }}
              onChangeRowsPerPage={(event) => {
                props.onChangeRowsPerPage(event);
                setRowsPerPage(Number(event.target.value));
                FetechData(Number(event.target.value), page, searchkey);
                setPage(0);
              }}
            />
          ),
        }}
        onRowClick={(event, rowData) => {
          history.push(`students/profile?email=${rowData?.email}`);
        }}
      />
    </>
  ) : (
    <Passcode
      passCode={passCode}
      isErrors={isError}
      setPassCode={setPassCode}
      handleClose={handleClose}
    />
  );
};
function stud(stud: any, arg1: (any: any) => boolean) {
  throw new Error("Function not implemented.");
}
