import MomentUtils from "@date-io/moment";
import { Button, Grid, TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  createConfigurationDetail,
  getConfigurationDetails,
  updateHomeConfigurationDetail,
} from "../../queries";
import { AppConfigurationItem, HomeConfigurationDetailItem } from "../../types";
import { AddItemsDialog } from "./AddItemsDialog";
import { bannerOptions, itemTypeOptions } from "./formOptions";
import { ItemsTable } from "./ItemsTable";

type ConfigurationDetailFormProps = {
  configId: string;
};

export const ConfigurationDetailForm: FC<ConfigurationDetailFormProps> = ({
  configId,
}) => {
  const queryClient = useQueryClient();
  const { id: detailId } = useParams<{ id: string }>();
  const { goBack } = useHistory();

  const { data: configurations = [] } = useQuery<HomeConfigurationDetailItem[]>(
    ["homeConfigs", configId],
    () => getConfigurationDetails(Number(configId)),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const config = configurations?.find(({ id }) => String(id) === detailId);

  const { mutateAsync: createConfig } = useMutation(createConfigurationDetail, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("homeConfigs");
      goBack();
    },
  });

  const { mutateAsync: updateConfig } = useMutation(
    updateHomeConfigurationDetail,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
        goBack();
      },
    }
  );

  const formik = useFormik<HomeConfigurationDetailItem>({
    initialValues: config || ({} as HomeConfigurationDetailItem),
    onSubmit: async (values) => {
      setprogress(true);
      const isNew = detailId === "new";
      values.items = items
        .reverse()
        .map((a) => ({ id: a.id, featured: a.featured }));

      let payload: HomeConfigurationDetailItem | FormData;

      if (bannerType?.value === "image") {
        values.banner = { type: "image" };
        payload = new FormData();
        payload.append("banner_image", bannerBlob);
        (Object.keys(values) as Array<keyof HomeConfigurationDetailItem>)
          .filter((key) => key !== "items")
          .forEach((key) => {
            (payload as FormData).append(key, String(values[key]));
          });
        payload.append("items", JSON.stringify(values.items));
      } else {
        values.banner = { type: "text", text: bannerText };
        payload = values;
      }

      // New Submit
      if (isNew) {
        await createConfig({ configId: Number(configId), payload });
        return;
      }
      await updateConfig({ id: Number(values.id), payload });
      return;
    },
    enableReinitialize: true,
  });
  const { handleChange, values, setFieldValue, handleSubmit } = formik;
  // values.valid_from = new Date();
  const [bannerType, setBannerType] = useState<
    | {
        title: string;
        value: string;
      }
    | undefined
    | null
  >(bannerOptions.find((type) => type.value === config?.banner?.type));
  const [bannerBlob, setBannerBlob] = useState<File | string>("");
  const [bannerText, setBannerText] = useState<string>(
    config?.banner?.text || ""
  );

  const [showAddDialog, setAddDialogVisibility] = useState(false);

  const [items, setItems] = useState<any[]>(values.items);
  const [progress, setprogress] = useState(false);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container style={{ padding: "1em" }} spacing={3} direction="column">
        <Grid item xs>
          {progress ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              // component={Link}
              // to={`${url}/detail/new`}
            >
              Save
            </Button>
          )}
        </Grid>
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs={"auto"}>
              <TextField
                required
                name="title"
                onChange={handleChange}
                value={values.title}
                label="Title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={"auto"}>
              <TextField
                required
                label="Description"
                variant="outlined"
                onChange={handleChange}
                value={values.description}
                name="description"
              />
            </Grid>
            <Grid item xs={"auto"}>
              <TextField
                required
                label="Display Order"
                variant="outlined"
                onChange={handleChange}
                value={values.display_order}
                name="display_order"
              />
            </Grid>
            <Grid item xs={"auto"}>
              <DatePicker
                format="DD/MM/yyyy"
                label="Valid From"
                views={["year", "month", "date"]}
                onChange={(date) => {
                  setFieldValue("valid_from", date?.startOf("date"));
                }}
                value={values.valid_from}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <DatePicker
                format="DD/MM/yyyy"
                label="Valid Through"
                views={["year", "month", "date"]}
                onChange={(date) =>
                  setFieldValue("valid_through", date?.endOf("date"))
                }
                value={values.valid_through}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Autocomplete
            options={itemTypeOptions}
            getOptionLabel={(option) => option.title}
            // value={itemType}
            renderInput={(params) => (
              <TextField {...params} label="Item Type" variant="outlined" />
            )}
            onChange={(_, v) => setFieldValue("item_type", v?.value)}
            value={itemTypeOptions.find(
              ({ value }) => value === values.item_type
            )}
            disabled={detailId !== "new"}
          />
        </Grid>
        {values.item_type === "promotion" ? (
          <Grid item xs>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <Autocomplete
                  options={bannerOptions}
                  getOptionLabel={(option) => option.title}
                  value={bannerType}
                  onChange={(_, value) => setBannerType(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Banner Type"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {bannerType ? (
                <>
                  {bannerType.value === "text" ? (
                    <Grid item xs={"auto"}>
                      <TextField
                        required
                        label="Text"
                        variant="outlined"
                        value={bannerText}
                        onChange={(e) => setBannerText(e.target.value)}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={"auto"}>
                      <input
                        type="file"
                        onChange={(event) => {
                          if (!event.target.files) {
                            return;
                          }
                          setBannerBlob(event.target.files[0]);
                        }}
                      />
                    </Grid>
                  )}
                </>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        {/* <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs={"auto"}>
              <Autocomplete
                options={featuredOptions}
                getOptionLabel={(option) => option.title}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Featured Items Display Type"
                    variant="outlined"
                  />
                )}
                onChange={(_, v) =>
                  setFieldValue("featured_display_type", v?.value)
                }
                value={featuredOptions.find(
                  ({ value }) => value === values.featured_display_type
                )}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <Autocomplete
                options={nonFeaturedOptions}
                getOptionLabel={(option) => option.title}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Non Featured Items Display Type"
                    variant="outlined"
                  />
                )}
                onChange={(_, v) =>
                  setFieldValue("non_featured_display_type", v?.value)
                }
                value={nonFeaturedOptions.find(
                  ({ value }) => value === values.non_featured_display_type
                )}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {values.item_type !== "promotion" ? (
          <>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddDialogVisibility((prev) => !prev)}
              >
                Add Items
              </Button>
            </Grid>
            <Grid item xs>
              {progress ? (
                <CircularProgress />
              ) : (
                <ItemsTable
                  items={items || []}
                  type={values.item_type}
                  onUpdate={(item) => {
                    const temp = Array.from(items);
                    const index = temp.findIndex(
                      ({ id }) => id === (item as { id: number }).id
                    );
                    temp[index] = item;

                    setItems(temp);
                  }}
                  onDelete={(item) => {
                    const temp = Array.from(items);
                    const index = temp.findIndex(
                      ({ id }) => id === (item as { id: number }).id
                    );
                    if (index > -1) {
                      temp.splice(index, 1);
                    }
                    console.log("delete");
                    console.log(temp);
                    setItems(temp);
                  }}
                />
              )}
            </Grid>
            {showAddDialog ? (
              <AddItemsDialog
                open={showAddDialog}
                onDialogClose={(item) => {
                  setAddDialogVisibility((prev) => !prev);
                  if (item) {
                    if (items && items.length) {
                      items.splice(
                        0,
                        0,
                        ...item.map((a) => ({ id: a.id, featured: false }))
                      );

                      setItems(items);
                    } else {
                      setItems(
                        item.map((a) => ({ id: a.id, featured: false }))
                      );
                    }
                  }
                }}
                addType={values.item_type as AppConfigurationItem}
              />
            ) : null}
          </>
        ) : null}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
