import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { getAllLiveStreams } from "../../../../Media/LiveStreams/queries";
import { LiveStream } from "../../../../Media/LiveStreams/types";

export const LiveStreamSelector: FC<{ onSelect: (values: any[]) => void }> = ({
  onSelect,
}) => {
  const { data: liveStreams = [] } = useQuery<LiveStream[]>(
    "live_streams",
    getAllLiveStreams,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const [selectedVideos, selectVideos] = useState<LiveStream[]>([]);
  return (
    <Grid container>
      <Grid item xs>
        <Autocomplete
          onChange={(_, values) => {
            selectVideos(values);
            onSelect(values);
          }}
          multiple
          options={liveStreams}
          getOptionLabel={(option) => option.title}
          value={selectedVideos}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Live Streams"
              placeholder="Live Streams"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
