import {
  Avatar,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import Highlight from "react-highlight.js";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router-dom";
import { ApiErrorLog } from "../typings/audit/ApiErrorLog";
import { StudentProfile } from "../typings/profile/User";
import { getErrorLog } from "./queries";

export const LogView = () => {
  const {
    params: { error_code },
  } = useRouteMatch<{ error_code: string }>();
  const { isLoading, data, isError } = useQuery<ApiErrorLog<StudentProfile>>(
    `error_logs/${error_code}`,
    () => getErrorLog(error_code),
    { refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return <h1>Loading</h1>;
  }

  if (!data) {
    return <h1>No Data</h1>;
  }

  if (isError) {
    return <h1>Error</h1>;
  }
  return (
    <Paper style={{ margin: "1em" }}>
      <Grid container style={{ padding: "1em" }} direction="column" spacing={1}>
        <Grid item xs>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs="auto">
              <Typography variant="h4">{data.error_message}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1"># {data.error_code}</Typography>
            </Grid>
            <Grid item xs="auto">
              {data.user_profile ? (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <Typography>{data.user_profile.full_name}</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Avatar
                      alt={data.user_profile.full_name}
                      src={data.user_profile.avatar_url || ""}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Chip label="Anonymous" variant="outlined" color="secondary" />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs="auto">
              <Chip
                label={data.http_request_method}
                variant="default"
                color="primary"
              />
            </Grid>
            <Grid item xs>
              <Highlight language="bash">{data.target_url}</Highlight>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs>
          {/* <Grid container spacing={1}> */}
          <Grid
            item
            xs={6}
            // xs="auto"
          >
            <Typography>Payload (If Any)</Typography>
            <Highlight language="json">
              {JSON.stringify(data.form_data)}
            </Highlight>
          </Grid>
          <Grid
            item
            xs={6}
            // xs="auto"
          >
            <Typography>Stack Trace (If Any)</Typography>
            <Highlight language="javascript">
              {data.error_stack_trace}
            </Highlight>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Paper>
  );
};
