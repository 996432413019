import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { VideoRelative } from "./VideoRelative";
import { Video } from "./Video";

export const VideoConfiguration = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <Video />} />
      <Route
        exact
        path={`${url}/:video_id`}
        render={() => <VideoRelative />}
      />
    </Switch>
  );
};
