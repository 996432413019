import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllSubjects } from "../../../queries/categories";
import { Subject } from "../../../shared/modelTypes";
import { getPricingTableColumns } from "./getPricingTableColumns";
import {
  addSubjectPricing,
  deletePricing,
  editSubjectPricing,
  getSubjectsPricing
} from "./queries";
import { SubjectPricing as SubjectPricingType } from "./types";

export const SubjectPricing = () => {
  const { data: pricing = [] } = useQuery(
    "subjects_pricing",
    getSubjectsPricing,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: subjects = [] } = useQuery<Subject[]>(
    "subjects",
    getAllSubjects,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();

  const { mutateAsync: mutCreatePricing } = useMutation(addSubjectPricing, {
    onSuccess: (newPricing) => {
      queryClient.setQueryData<Partial<SubjectPricingType>[]>(
        "subjects_pricing",
        (prev) => [...(prev || []), newPricing]
      );
    },
  });

  const { mutateAsync: updateSubjectPricing } = useMutation(
    editSubjectPricing,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subjects_pricing");
      },
    }
  );

  const { mutateAsync: mutDeletePricing } = useMutation(deletePricing, {
    onSuccess: (params) => {
      queryClient.invalidateQueries("subjects_pricing");
    },
  });
  const columnConfig = getPricingTableColumns({ subjects });

  return (
    <Grid container direction="column" style={{ padding: "1em" }}>
      <Grid item xs="auto">
        <MaterialTable
          columns={columnConfig}
          data={pricing}
          editable={{
            onRowAdd: mutCreatePricing,
            onRowDelete: ({ id }) => mutDeletePricing(id),
            onRowUpdate: (pricing) => updateSubjectPricing(pricing),
          }}
          title="Manage Subject Pricing"
        />
      </Grid>
    </Grid>
  );
};
