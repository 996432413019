import { Grid, Typography } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React, { FC } from "react";
import { useQuery } from "react-query";
import {
  getAllSubjects,
  getAllSystems,
  getAllTopics,
} from "../../../../../queries/categories";
import { getAllLiveStreams } from "../../../../Media/LiveStreams/queries";
import { Video } from "../../../../Media/types";
import { Item, ItemType } from "../../types";
import { getVideos } from "./queries";

// type Item = {
//   value: number;
//   title: string;
//   is_featured: boolean;
//   id?: number;
// };

const getColumns = (
  titleLookup: { id: number; title: string }[] | undefined
): Column<Item>[] => [
  {
    title: "Title",
    type: "string",
    editable: "never",
    render: (data) => {
      if (!(data as { id: number }).id) {
        return <Typography>NO ID</Typography>;
      }
      if (titleLookup) {
        return (
          <Typography>
            {
              (
                titleLookup.find(
                  ({ id }) => id === Number((data as { id: number }).id)
                ) || {}
              ).title
            }
          </Typography>
        );
      }
      return <Typography>NO ID</Typography>;
    },
  },
  {
    title: "Featured",
    field: "featured",
    type: "boolean",
  },
];

type ItemsTableProps = {
  items: Item[];
  type: ItemType;
  onUpdate: (item: Item) => void;
  onDelete: (item: Item) => void;
};

export const ItemsTable: FC<ItemsTableProps> = ({
  items,
  type,
  onUpdate,
  onDelete,
}) => {
  const { data: videos = [] } = useQuery<Video[]>("videos", getVideos, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const { data: subjects = [] } = useQuery<{ title: string; id: number }[]>(
    "subjects",
    getAllSubjects,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: systems = [] } = useQuery<{ title: string; id: number }[]>(
    "systems",
    () => getAllSystems(),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: topics = [] } = useQuery<{ title: string; id: number }[]>(
    "topics",
    () => getAllTopics(),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: liveStreams = [] } = useQuery<{ title: string; id: number }[]>(
    "live_streams",
    getAllLiveStreams,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const lookupMap: Partial<
    { [key in ItemType]: { title: string; id: number }[] }
  > = {
    video: videos,
    subject: subjects,
    system: systems,
    topic: topics,
    live_stream: liveStreams,
    flash_note: videos,
  };

  const columns = getColumns(lookupMap[type]);

  return (
    <Grid container>
      <Grid item xs>
        <MaterialTable
          isLoading={false}
          columns={columns}
          data={
            items || [
              {
                is_featured: true,
                title: "asd",
              },
            ]
          }
          editable={{
            onRowUpdate: async (data) => await onUpdate(data),
            onRowDelete: async (data) => await onDelete(data),
          }}
          options={{
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
          }}
        />
      </Grid>
    </Grid>
  );
};
