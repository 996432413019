import { Button, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { FC, useState } from "react";
import { AppConfigurationItem, ItemType } from "../../types";
import { AddItemsDialog } from "./AddItemsDialog";
import {
  bannerOptions,
  featuredOptions,
  itemTypeOptions,
  nonFeaturedOptions,
} from "./formOptions";
import { ItemsTable } from "./ItemsTable";

export const GroupSelector: FC<{ onSelect: (values: any[]) => void }> = ({
  onSelect,
}) => {
  const [bannerType, setBannerType] = useState<{
    title: string;
    value: string;
  } | null>(null);

  const [itemType, setItemType] = useState<{
    title: string;
    value: string;
  }>(itemTypeOptions[0]);

  const [showAddDialog, setAddDialogVisibility] = useState(false);

  const [items, setItems] = useState<any[] | null>([]);

  return (
    <Grid container style={{ padding: "1em" }} spacing={3} direction="column">
      <Grid item xs="auto">
        <Grid container spacing={2}>
          <Grid item xs={"auto"}>
            <TextField required label="Title" variant="outlined" />
          </Grid>
          <Grid item xs={"auto"}>
            <TextField required label="Description" variant="outlined" />
          </Grid>
          <Grid item xs={"auto"}>
            <TextField required label="Display Order" variant="outlined" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Autocomplete
          options={itemTypeOptions.filter(({ value }) => value !== "group")}
          getOptionLabel={(option) => option.title}
          value={itemType}
          onChange={(_, data) => {
            if (data) {
              setItemType(data);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Item Type" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Autocomplete
              options={bannerOptions}
              getOptionLabel={(option) => option.title}
              value={bannerType}
              onChange={(_, value) => setBannerType(value)}
              renderInput={(params) => (
                <TextField {...params} label="Banner Type" variant="outlined" />
              )}
            />
          </Grid>
          {bannerType ? (
            <>
              {bannerType.value === "text" ? (
                <Grid item xs={"auto"}>
                  <TextField required label="Text" variant="outlined" />
                </Grid>
              ) : (
                <Grid item xs={"auto"}>
                  <input
                    type="file"
                    onChange={(event) => {
                      console.log(event);
                      if (!event.target.files) {
                        return;
                      }
                    }}
                  />
                </Grid>
              )}
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={"auto"}>
            <Autocomplete
              options={featuredOptions}
              getOptionLabel={(option) => option.title}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Featured Items Display Type"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <Autocomplete
              options={nonFeaturedOptions}
              getOptionLabel={(option) => option.title}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Non Featured Items Display Type"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {itemType?.value !== "promotion" ? (
        <>
          <Grid item xs={"auto"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddDialogVisibility((prev) => !prev)}
            >
              Add Items
            </Button>
          </Grid>
          <Grid item xs>
            <ItemsTable
              items={items || []}
              type={itemType.value as ItemType}
              onUpdate={() => {}}
              onDelete={() => {}}
            />
          </Grid>
          {showAddDialog ? (
            <AddItemsDialog
              open={showAddDialog}
              onDialogClose={(items) => {
                setAddDialogVisibility((prev) => !prev);
                setItems(items);
                if (items) {
                  onSelect(items);
                }
              }}
              addType={itemType?.value as AppConfigurationItem}
            />
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};
