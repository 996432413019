import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AddCoupon } from "./AddCoupon";
import { CouponsList } from "./coupons";

export const CouponConfiguration = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <CouponsList />} />
      <Route exact path={`${url}/new`} render={() => <AddCoupon />} />
      <Route exact path={`${url}/edit`} render={() => <AddCoupon />} />
    </Switch>
  );
};
