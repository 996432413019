import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { createCoupon, updateCoupon } from "../../../queries/coupons";
import { Coupons } from "../../../shared/modelTypes";
import {
  couponTypes,
  GetSubjectOptions,
  GetAllColleges,
  GetAllStudents,
  GetAllCBME,
} from "./formOptions";

import { useLocation } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { dark } from "@material-ui/core/styles/createPalette";
import { getAllStudentsList } from "../../../queries/student";

type PropsTypes = {
  rowData: Coupons | null;
};

export const AddCoupon = () => {
  const location = useLocation();
  const rowData = location.state;
  const queryClient = useQueryClient();
  const history = useHistory();
  const [validation, setValidation] = useState({
    applicablecollege: false,
    applicableuser: false,
    applicablecbme: false,
    title: false,
    discount_percentage: false,
    max_discount: false,
    coupon_code: false,
    user_max_limit: false,
    global_max_limit: false,
    coupon_type: false,
  });

  const subjectOptions = GetSubjectOptions();
  const cbmeOption = GetAllCBME();
  const allStudentList = GetAllStudents();
  const collegeOptions = GetAllColleges();
  const [selectedSubject, setSelectedSubject] = useState([
    { value: "", title: "" },
  ]);
  const [selectedCollege, setSelectedCollege] = useState([
    { value: "", name: "" },
  ]);
  const [selectedCBME, setSelectedCBME] = useState([{ value: "", name: "" }]);
  const [selectedUser, setSelectedUser] = useState([{ value: "", name: "" }]);
  const [studentList, setStudentList] = useState([{ value: "", name: "", phone: "" }]);
  const [userValue, setUserValue] = useState("");
  const [pagination, setPagination] = useState({ limit: 20, page: 1 })

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getUsers(userValue)
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [userValue]);

  const getUsers = (key: any) => {
    const users: any = allStudentList.filter((user) => user.name.includes(key)).slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit);
    if (pagination.page != 1) return setStudentList([...studentList, ...users])
    setStudentList(users)
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  let select = "";
  const formik = useFormik<Coupons>({
    initialValues: rowData
      ? (rowData as Coupons)
      : ({
        is_suspended: false,
        is_userverify_required: false,
        is_college_specific: false,
        is_idcard_required: false,
        valid_through: new Date(),
        valid_from: new Date(),
        applicable_colleges: ["empty"],
        applicable_subjects: ["empty"],
        applicable_users: ["empty"],
        applicable_cbme_subjects: ["empty"],
      } as Coupons),
    onSubmit: async (values) => {
      const payload = { ...values };
      //@ts-ignore
      payload.valid_from = new Date(values.valid_from).toISOString();
      //@ts-ignore
      payload.valid_through = new Date(values.valid_through).toISOString();
      values.applicable_subjects.find((element) => {
        if (element === "id") {
          values.applicable_subjects = [];
          subjectOptions.map((id) => {
            if (id.value != "id") {
              values.applicable_subjects.push(id.value);
            }
          });
        }
      });

      var subjectList = values.applicable_subjects.filter(function (e) {
        return e !== "empty";
      });
      payload.applicable_subjects = subjectList;
      values.applicable_users.find((element) => {
        if (element === "id") {
          values.applicable_users = [];
          allStudentList?.map((id) => {
            if (id.value != "id") {
              values.applicable_users.push(id.value);
            }
          });
        }
      });
      var userList = values.applicable_users?.filter(function (e) {
        return e !== "empty";
      });
      payload.applicable_users = userList;

      //
      values.applicable_cbme_subjects.find((element) => {
        if (element === "id") {
          values.applicable_cbme_subjects = [];
          cbmeOption.map((id) => {
            if (id.value != "id") {
              values.applicable_cbme_subjects.push(id.value);
            }
          });
        }
      });
      var cbmeList = values.applicable_cbme_subjects?.filter(function (e) {
        return e !== "empty";
      });
      payload.applicable_cbme_subjects = cbmeList;
      //
      values.applicable_colleges.find((element) => {
        if (element === "id") {
          values.applicable_colleges = [];
          collegeOptions.map((id) => {
            if (id.value != "id") {
              values.applicable_colleges.push(id.value);
            }
          });
        }
      });
      var collegesList = values.applicable_colleges?.filter(function (e) {
        return e !== "empty";
      });
      payload.applicable_colleges = collegesList;
      if (
        (values.applicable_colleges.length < 1 ||
          values.applicable_colleges[0] === "empty") &&
        values.is_college_specific
      ) {
        setValidation({ ...validation, applicablecollege: true });
      } else {
        setValidation({ ...validation, applicablecollege: false });
      }
      if (
        (values.applicable_users.length < 1 ||
          values.applicable_users[0] === "empty") &&
        values.is_user_specific
      ) {
        setValidation({ ...validation, applicableuser: true });
      } else {
        if (values.id) {
          return mutUpdateCoupon({ id: values.id, payload: payload });
        }
        return mutCreateCoupon(payload);
        setValidation({ ...validation, applicableuser: false });
      }
    },
  });
  const { handleChange, values, setFieldValue, handleSubmit } = formik;

  const { mutateAsync: mutCreateCoupon } = useMutation(createCoupon, {
    onSuccess: (newCoupon) => {
      queryClient.setQueryData<Partial<Coupons>[]>("coupons", (prev) => [
        ...(prev || []),
        newCoupon,
      ]);
      history.goBack();
    },
  });

  const { mutateAsync: mutUpdateCoupon } = useMutation(updateCoupon, {
    onSuccess: (parms) => {
      queryClient.invalidateQueries();
      history.goBack();
    },
  });

  return (
    <Container maxWidth="xl">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          container
          spacing={2}
          style={{ padding: "2em" }}
          direction="column"
        >
          <Grid item xs>
            <Typography variant="h6">
              {rowData ? "Edit Coupon" : "Add New Coupon"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
                {validation?.title && <span>{"Title required"}</span>}
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Coupon Code"
                  variant="outlined"
                  name="coupon_code"
                  value={values.coupon_code}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="User Maximum Limit"
                  variant="outlined"
                  name="user_max_limit"
                  value={values.user_max_limit}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Global Maximum Limit"
                  variant="outlined"
                  name="global_max_limit"
                  value={values.global_max_limit}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Discount Percentage"
                  variant="outlined"
                  name="discount_percentage"
                  value={values.discount_percentage}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Maximum Discount"
                  variant="outlined"
                  name="max_discount"
                  value={values.max_discount}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={subjectOptions}
                    disableCloseOnSelect
                    value={
                      values.applicable_subjects
                        ? subjectOptions.filter((item: any) =>
                          values.applicable_subjects.includes(item.value)
                        )
                        : []
                    }
                    onChange={(_, value) => {
                      setFieldValue(
                        "applicable_subjects",
                        value.map(({ value }: any) => value)
                      );
                      setSelectedSubject(value);
                    }}
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, state) => {
                      const selectSubjectIndex = selectedSubject.findIndex(
                        (subject) => subject.title.toLowerCase() === "all"
                      );
                      if (selectSubjectIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.title}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Applicable Subjects"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    options={couponTypes}
                    getOptionLabel={(option) => option.title}
                    value={couponTypes.find(
                      (item: any) => item.value === values.coupon_type
                    )}
                    onChange={(_, value) => {
                      setFieldValue("coupon_type", value?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Coupon type"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={collegeOptions}
                    disableCloseOnSelect
                    value={
                      values.applicable_colleges
                        ? collegeOptions.filter((item: any) =>
                          values.applicable_colleges.includes(item.value)
                        )
                        : []
                    }
                    onChange={(_, value) => {
                      setFieldValue(
                        "applicable_colleges",
                        value.map(({ value }: any) => value)
                      );
                      setSelectedCollege(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => {
                      const selectCollegeIndex = selectedCollege.findIndex(
                        (subject) => subject.name.toLowerCase() === "all"
                      );
                      if (selectCollegeIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          label={
                            validation.applicablecollege ? (
                              <span style={{ color: "red" }}>
                                *Please choose college
                              </span>
                            ) : (
                              "Applicable Colleges"
                            )
                          }
                          variant="outlined"
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                id=""
                                style={{
                                  overflow: "scroll",
                                  height: "150px",
                                  overflowX: "hidden",
                                  width: "500px",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    loading={true}
                    options={studentList}
                    disableCloseOnSelect
                    inputValue={userValue}
                    ListboxProps={{
                      onScroll: (event: React.SyntheticEvent) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight > listboxNode.scrollHeight - 20) {
                          setPagination({
                            limit: pagination.limit + 20, page: pagination.page + 1
                          })
                          getUsers(userValue)
                        }
                      }
                    }}
                    value={
                      values.applicable_users
                        ? allStudentList.filter((item: any) =>
                          values.applicable_users.includes(item.value)
                        )
                        : []
                    }
                    onChange={(_, value) => {
                      setFieldValue(
                        "applicable_users",
                        value.map(({ value }: any) => value)
                      );
                      setSelectedUser(value);
                      setPagination({
                        limit: 20, page: 1
                      })
                      setUserValue('');
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => {
                      const selectUserIndex = selectedUser.findIndex(
                        (subject) => subject.name.toLowerCase() === "all"
                      );
                      if (selectUserIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          label={
                            validation.applicableuser ? (
                              <span style={{ color: "red" }}>
                                *Please choose Users
                              </span>
                            ) : (
                              "Applicable Users"
                            )
                          }
                          onChange={(e) => {
                            setUserValue(e.target.value)
                            setPagination({
                              limit: 20, page: 1
                            })
                            setStudentList([])
                          }}
                          variant="outlined"
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                id=""
                                style={{
                                  overflow: "scroll",
                                  height: "150px",
                                  overflowX: "hidden",
                                  width: "500px",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={cbmeOption}
                    disableCloseOnSelect
                    value={
                      values.applicable_cbme_subjects
                        ? cbmeOption.filter((item: any) =>
                          values.applicable_cbme_subjects.includes(item.value)
                        )
                        : []
                    }
                    onChange={(_, value) => {
                      setFieldValue(
                        "applicable_cbme_subjects",
                        value.map(({ value }: any) => value)
                      );
                      setSelectedCBME(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => {
                      const selectUserIndex = selectedCBME.findIndex(
                        (subject) => subject.name.toLowerCase() === "all"
                      );
                      if (selectUserIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          label={
                            validation.applicablecbme ? (
                              <span style={{ color: "red" }}>
                                *Please choose Users
                              </span>
                            ) : (
                              "Applicable CBME"
                            )
                          }
                          variant="outlined"
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                id=""
                                style={{
                                  overflow: "scroll",
                                  height: "150px",
                                  overflowX: "hidden",
                                  width: "500px",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={3}>
                  <DatePicker
                    fullWidth
                    format="DD/MM/yyyy"
                    label="Valid From"
                    name="valid_from"
                    views={["year", "month", "date"]}
                    onChange={(date) =>
                      setFieldValue("valid_from", date?.endOf("date"))
                    }
                    value={values.valid_from}
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <DatePicker
                    fullWidth
                    format="DD/MM/yyyy"
                    label="Valid Through"
                    name="valid_through"
                    views={["year", "month", "date"]}
                    onChange={(date) =>
                      setFieldValue("valid_through", date?.endOf("date"))
                    }
                    value={values.valid_through}
                    inputVariant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_suspended}
                        onChange={handleChange}
                        name="is_suspended"
                        color="primary"
                        value={values.is_suspended}
                      />
                    }
                    label="Suspended"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_userverify_required}
                        onChange={handleChange}
                        name="is_userverify_required"
                        color="primary"
                        value={values.is_userverify_required}
                      />
                    }
                    label="User Verification"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_college_specific}
                        onChange={handleChange}
                        name="is_college_specific"
                        color="primary"
                        value={values.is_college_specific}
                      />
                    }
                    label="College Specific"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_idcard_required}
                        onChange={handleChange}
                        name="is_idcard_required"
                        color="primary"
                        value={values.is_idcard_required}
                      />
                    }
                    label="College ID Upload"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_user_specific}
                        onChange={handleChange}
                        name="is_user_specific"
                        color="primary"
                        value={values.is_user_specific}
                      />
                    }
                    label="User Specific"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    name="description"
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ paddingTop: "6em" }}>
              {" "}
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>{" "}
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Container>
  );
};
