import MaterialTable, { Column } from "material-table";
import * as React from "react";
import { useQuery } from "react-query";
import { getStudentBookemark } from "../../queries/student";
import { StudentBookmark } from "../../shared/modelTypes";

export const StudentBookmarks = (props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) => {
  const { children, value, index, ...other } = props;

   const { isLoading, data: bookmark = [] } = useQuery(
    ["students/bookemarks", children],
    () => getStudentBookemark(children),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const columnDefintion: Column<StudentBookmark>[] = [
    {
      title: "Video",
      field: "video_title",
    },
    {
      title: "Title",
      field: "bookmark_title",
    },
    {
      title: "Description",
      field: "description",
    },
    
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MaterialTable
          isLoading={isLoading}
          title="Bookmark videos"
          data={bookmark}
          columns={columnDefintion.map((c) => ({
            ...c,
            tableData: undefined,
          }))}
        />
      )}
    </div>
  );
};
