import { CssBaseline } from "@material-ui/core";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { AppProvider } from "./AppContext";
import { AppViews } from "./components/AppViews";
import { Login } from "./components/Login/Login";
import { PrivateRoute } from "./shared/PrivateRoute";
import { ThemeProvider } from "./theme";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" render={() => <Login />} />
              <PrivateRoute
                path="/"
                render={() => <AppViews />}
                fallbackPath="/login"
              />
            </Switch>
          </BrowserRouter>
        </QueryClientProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
