import { ThemeOptions } from "@material-ui/core";

export const portalTheme: ThemeOptions = {
  palette: {
    type: "light",
    primary: { main: "#1f798a", contrastText: "#fafafa" },
    secondary: { main: "#d0f6f4", contrastText: "#3d4b82" },
  },
};

export const reactSelectCustomStyle = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    color: "#000",
  }),
};
