import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { Video } from "../../../../Media/types";
import { getVideos } from "./queries";

export const VideoSelector: FC<{ onSelect: (values: any[]) => void }> = ({
  onSelect,
}) => {
  const { data: videos = [] } = useQuery<Video[]>("videos", getVideos, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const [selectedVideos, selectVideos] = useState<Video[]>([]);
  return (
    <Grid container>
      <Grid item xs>
        <Autocomplete
          onChange={(_, values) => {
            selectVideos(values);
            onSelect(values);
          }}
          multiple
          options={videos}
          getOptionLabel={(option) => option.title}
          value={selectedVideos}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Videos"
              placeholder="Videos"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
