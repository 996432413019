import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { api } from "../../api";
import { AppContext } from "../../AppContext";
import { queries } from "../../queries/portal";

export const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const appContext = useContext(AppContext);
  const { isLoading, data } = useQuery(["portal", "user"], queries.getMe, {
    refetchOnWindowFocus: false,
  });
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!data) {
    return null;
  }

  return (
    <div>
      <Button
        disabled={isLoading}
        endIcon={
          data.avatar_url ? (
            <Avatar alt={data.email} src={data.avatar_url} />
          ) : (
            <AccountCircle />
          )
        }
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenu}
      >
        Welcome, {data.full_name}
      </Button>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem
          onClick={async () => {
            await api.get("auth/logout");
            appContext.dispatch({
              type: "AUTH",
              payload: { isAuthenticated: false },
            });
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};
