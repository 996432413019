import { Issues } from "./components/Administration/Issues";
import { Subscription } from "./components/Administration/Subscription";
import { AppBannerConfiguration } from "./components/Configuration/AppBannerConfiguration/AppBannerConfiguration";
import { AppHomeConfiguration } from "./components/Configuration/AppHomeConfiguration/AppHomeConfiguration";
import { AppReleaseVersions } from "./components/Configuration/AppReleaseVersion/AppReleaseVersions";
import { CouponConfiguration } from "./components/Configuration/coupons/CouponConfiguration";
import { S3Bucket } from "./components/Configuration/S3Bucket/S3Bucket";
import { States } from "./components/Configuration/State/Index";
import { Colleges } from "./components/Configuration/College/Index";
import { Subjects } from "./components/CourseCategories";
import { Systems } from "./components/CourseCategories/Systems";
import { Topics } from "./components/CourseCategories/Topics";
import { ErrorLogsView } from "./components/ErrorLogsView/ErrorLogsView";
import { InCompleteLogin } from "./components/Login/InCompleteLogin";
// import { Video } from "./components/ManageVideo/Video";
import { VideoConfiguration } from "./components/ManageVideo/VideoConfiguration";
import { MediaSourceConfig } from "./components/Media/MediaSource/MediaSourceConfig";
import { Premieres } from "./components/Media/Premieres/Premieres";
import { SalesTax } from "./components/Sales/SalesTax/SalesTax";
import { SubjectPricing } from "./components/Sales/SubjectPricing/SubjectPricing";
import { NavItem } from "./components/SideDrawer/types";
import { InCompleteRegistration } from "./components/Student/InCompleteRegistration";
import { StudentConfig } from "./components/Student/StudentConfig";
import { AppShareContent } from "./components/Configuration/AppShareContent/index";
import { CBME } from "./components/Administration/Cbme";
export const routeConfig: NavItem[] = [
  {
    title: "Administration",
    subRoutes: [
      { path: "/view_errors", title: "API Errors", component: ErrorLogsView },
      // { title: "Students", component: Student, path: "/students" },
      {
        title: "Students",
        component: StudentConfig,
        path: "/students",
      },
      {
        title: "In-complete Login",
        component: InCompleteLogin,
        path: "/incomplete_login",
      },
      {
        title: "In-complete Registration",
        component: InCompleteRegistration,
        path: "/incomplete_registration",
      },
      {
        title: "Issues",
        component: Issues,
        path: "/issue",
      },
      {
        title: "Subscription",
        component: Subscription,
        path: "/subscription",
      },
      {
        title: "CBME",
        component: CBME,
        path: "/cbme",
      },
    ],
  },
  {
    title: "Sales Managemnt",
    subRoutes: [
      {
        title: "Manage Subject Pricing",
        component: SubjectPricing,
        path: "/sales/subject_pricing",
      },
      {
        title: "Manage Sales Tax",
        component: SalesTax,
        path: "/sales/sales_tax",
      },
    ],
  },
  {
    title: "Manage Media",
    subRoutes: [
      {
        title: "Manage Premieres",
        component: Premieres,
        path: "/media/premieres",
      },
      {
        path: "/media/media_source",
        title: "Manage Media Source",
        component: MediaSourceConfig,
        // component: AddMediaSource,
      },
      {
        path: "/media/video",
        title: "Manage Videos",
        component: VideoConfiguration,
      },
      {
        title: "Subjects",
        path: "/administration/subjects",
        component: Subjects,
      },
      {
        title: "Systems",
        path: "/administration/systems",
        component: Systems,
      },
      {
        title: "Topics",
        path: "/administration/topics",
        component: Topics,
      },
    ],
  },
  {
    title: "Configuration",
    subRoutes: [
      {
        title: "State",
        component: States,
        path: "/config/state",
      },
      {
        title: "College",
        component: Colleges,
        path: "/config/college",
      },
      {
        title: "App Share Content",
        component: AppShareContent,
        path: "/config/app_share_content",
      },
      {
        title: "App Home View Configuration",
        component: AppHomeConfiguration,
        path: "/config/app_home",
      },
      {
        title: "App Banner Configuration",
        component: AppBannerConfiguration,
        path: "/config/banner",
      },
      {
        title: "S3Bucket",
        component: S3Bucket,
        path: "/config/s3bucket",
      },
      {
        title: "Coupons",
        component: CouponConfiguration,
        path: "/config/coupons",
      },
      {
        title: "App Release Version",
        component: AppReleaseVersions,
        path: "/config/app_release_version",
      },
    ],
  },
];
