import { Column } from "material-table";
import { SalesTaxItem } from "./types";

export const getSalesTaxColumns = (): Column<SalesTaxItem>[] => {
  return [
    {
      title: "Tax Label",
      field: "tax_label",
      type: "string",
      validate: (rowData) => Boolean(rowData.tax_label),
    },
    {
      title: "Tax Description",
      field: "tax_description",
      type: "string",
    },
    {
      title: "Percentile",
      field: "tax_percentile",
      type: "numeric",
      validate: (rowData) => Boolean(rowData.tax_percentile),
    },
    {
      title: "Is Enabled?",
      field: "is_enabled",
      type: "boolean",
    },
  ];
};
