import MaterialTable, { Column } from "material-table";
import * as React from "react";
import { useQuery } from "react-query";
import { getStudentWatchHistory } from "../../queries/student";
import { StudentWatchHistories } from "../../shared/modelTypes";

export const StudentWatchHistory = (props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) => {
  const { children, value, index, ...other } = props;

  const { isLoading, data: history = [] } = useQuery(
    ["students/history", children],
    () => getStudentWatchHistory(children),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columnDefintion: Column<StudentWatchHistories>[] = [
    {
      title: "Watched Date",
      field: "request_time",
      type: "date",
    },
    {
      title: "Time",
      field: "time",
    },
    {
      title: "Video",
      field: "video_title",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Watched duration",
      field: "test_duration",
    }    
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MaterialTable
          isLoading={isLoading}
          title="History"
          data={history}
          columns={columnDefintion.map((c) => ({
            ...c,
            tableData: undefined,
          }))}
        />
      )}
    </div>
  );
};
