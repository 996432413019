import React, { useState, useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import { HomeConfigurationItems } from "../../../../shared/modelTypes";
import * as queries from "../queries";
import moment from "moment";
import { getAllConfigurations } from "../queries";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

export const ConfigurationTable = () => {
  const history = useHistory();
  const [ConfigList, SetConfigList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const { isLoading, data: configurations = [] } = useQuery(
  //   "homeConfigs",
  //   queries.getAllConfigurations,
  //   {
  //     staleTime: 60000,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    FetechData();
  }, []);

  const FetechData = async () => {
    setLoading(true);
    let config = await getAllConfigurations();
    if (await config) {
      SetConfigList(await config);
      setLoading(false);
    } else {
    }
  };
  const { url } = useRouteMatch();
  const { push } = useHistory();

  // const columnDefinitions = getConfigurationColumns();
  const columnDefintion: Column<HomeConfigurationItems>[] = [
    {
      title: "Title",
      field: "title",
      type: "string",
      validate: (rowData) => Boolean(rowData.title),
    },
    {
      title: "Description",
      field: "description",
      type: "string",
    },
    {
      title: "Valid From",
      field: "valid_from",
      type: "datetime",
      initialEditValue: moment().startOf("minute"),
      validate: (rowData) => Boolean(rowData.valid_from),
      render: ({ valid_from }) => {
        return moment(valid_from)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },
    {
      title: "Valid Through",
      field: "valid_through",
      type: "datetime",
      initialEditValue: moment().endOf("hour"),
      validate: (rowData) => Boolean(rowData.valid_through),
      render: ({ valid_through }) => {
        return moment(valid_through)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },
    {
      title: "Is Enabled?",
      field: "is_enabled",
      type: "boolean",
      // validate: (rowData) => Boolean(rowData.is_enabled),
    },
  ];

  const queryClient = useQueryClient();

  const { mutateAsync: createConfig } = useMutation(
    queries.createConfiguration,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("homeConfigs");
      },
    }
  );

  // const { mutateAsync: editConfig } = useMutation(queries.editConfiguration, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries("homeConfigs");
  //   },
  // });
  const editConfig = (rowData: any) => {
    delete rowData["globalcount"];
    history.push(`${url}/edit`, rowData);
  };

  const { mutateAsync: deleteConfig } = useMutation(
    queries.delteConfiguration,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("homeConfigs");
        FetechData();
      },
    }
  );

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          style={{ padding: "2em" }}
          direction="column"
        >
          <Grid item xs>
            <Typography variant="h6"> Configuration</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`${url}/new`}
            >
              Add Configurations
            </Button>
          </Grid>
          <Grid item xs>
            <MaterialTable
              isLoading={isLoading}
              title="Configurations"
              columns={columnDefintion.map((c) => ({
                ...c,
                tableData: undefined,
              }))}
              data={ConfigList}
              onRowClick={(_, config) => {
                if (config) {
                  push(`${url}/deatils/${config.id}`);
                }
              }}
              editable={{
                // onRowAdd: createConfig,
                // onRowUpdate: (row) => editConfig(old),
                onRowDelete: (old) => deleteConfig(old.id),
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Edit data",
                  onClick: (event, rowData: any) => {
                    delete rowData.tableData;
                    editConfig(rowData);
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
