import { Avatar } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  createSubject,
  deleteSubject,
  getAllSubjects,
  updateSubject,
} from "../../queries/categories";
import { Subject } from "../../shared/modelTypes";

export const Subjects = () => {
  const history = useHistory();
  const { isLoading, data: subjects = [] } = useQuery(
    "subjects",
    getAllSubjects,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();

  const { mutateAsync: mutCreateSubject } = useMutation(createSubject, {
    onSuccess: (newSubject) => {
      queryClient.setQueryData<Partial<Subject>[]>("subjects", (prev) => [
        ...(prev || []),
        newSubject,
      ]);
    },
  });

  const { mutateAsync: mutDeleteSubject } = useMutation(deleteSubject, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateSubject } = useMutation(updateSubject, {
    onSuccess: (updatingSubject) => {
      queryClient.invalidateQueries();
    },
  });

  const columnDefintion: Column<Subject>[] = [
    {
      title: "Title",
      field: "title",
      validate: (rowData) => Boolean(rowData.title),
    },
    {
      title: "Subject Icon",
      field: "icon_blob",
      render: ({ thumbnail_url, title }) => {
        if (!thumbnail_url) {
          return null;
        }
        return <Avatar src={thumbnail_url} alt={`icon_for_${title}`} />;
      },
      editComponent: ({ onChange }) => (
        <input
          type="file"
          onChange={(event) => {
            if (!event.target.files) {
              return;
            }
            onChange(event.target.files[0]);
          }}
        />
      ),
    },
    {
      title: "Type of Subject",
      field: "subject_type",
      lookup: {
        theory: "Theory",
        practical: "Practical",
      },
      validate: (rowData) => Boolean(rowData.subject_type),

    },
    {
      title: "Year",
      field: "year",
      validate: (rowData) => Boolean(rowData.year),
      type: "numeric",
    },
    {
      title: "List Order",
      field: "list_order",
      validate: (rowData) => Boolean(rowData.list_order),
      type: "numeric",
    },
  ];

  return (
    <MaterialTable
      isLoading={isLoading}
      title="Subjects"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      data={subjects}
      editable={{
        onRowAdd: (subject) => {
          if (subject.icon_blob) {
            const formData = new FormData();
            formData.append("icon", subject.icon_blob);
            (Object.keys(subject).filter(
              (key) => !["icon_blob", "icon_url"].includes(key)
            ) as Array<keyof Omit<Subject, "icon_blob" | "icon_url">>).forEach(
              (key) => {
                formData.append(key, String(subject[key]));
              }
            );
            return mutCreateSubject(formData);
          }
          return mutCreateSubject(subject);
        },
        onRowUpdate: (updatedSubject) => {
          if (updatedSubject.icon_blob) {
            const formData = new FormData();
            formData.append("icon", updatedSubject.icon_blob);
            (Object.keys(updatedSubject).filter(
              (key) => !["icon_blob", "icon_url"].includes(key)
            ) as Array<keyof Omit<Subject, "icon_blob" | "icon_url">>).forEach(
              (key) => {
                formData.append(key, String(updatedSubject[key]));
              }
            );
            return mutUpdateSubject({
              id: updatedSubject.id,
              payload: formData,
            });
          }
          return mutUpdateSubject({
            id: updatedSubject.id,
            payload: updatedSubject,
          });
        },
        onRowDelete: mutDeleteSubject,
      }}
      onRowClick={(event, rowData) => {
        history.push(`/administration/systems?subject_id=${rowData?.id}`);
      }}
    />
  );
};
