import MaterialTable, { Column } from 'material-table';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppShare } from '../../../shared/modelTypes';
import * as React from 'react';
import {
  createContent,
  deleteContent,
  getAllContent,
  updateContent,
} from '../../../queries/appshare';
import { TextField } from '@material-ui/core';

export const AppShareContent = () => {
  const { isLoading, data: share = [] } = useQuery(
    'config/app_share_content',
    getAllContent,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const columnDefintion: Column<AppShare>[] = [
    {
      title: 'Titile',
      field: 'title',
    },
    {
      title: 'Description',
      field: 'description',
      editComponent: (props: any) => (
        <TextField
          fullWidth
          label='Description'
          variant='outlined'
          name='description'
          multiline
          rows={10}
          value={props.value}
          onChange={(event) => {
            if (!event.target.value) {
              return;
            }
            props.onChange(event.target.value);
          }}
        />
      ),
    },
  ];
  const queryClient = useQueryClient();
  const { mutateAsync: mutCreateContent } = useMutation(createContent, {
    onSuccess: (newContent) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutDeleteContent } = useMutation(deleteContent, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateContent } = useMutation(updateContent, {
    onSuccess: (updatedContent) => {
      queryClient.invalidateQueries();
    },
  });
  return (
    <MaterialTable
      isLoading={isLoading}
      data={share}
      title='App Share Content'
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutCreateContent,
        onRowUpdate: (updatingContent) =>
          mutUpdateContent({
            id: updatingContent.id,
            payload: updatingContent,
          }),
        onRowDelete: mutDeleteContent,
      }}
    />
  );
};
