import { MutationFunction } from "react-query";
import { api } from "../api";
import { AppShare } from "../shared/modelTypes";

export const getAllContent = () => {
  return api.get("config/app_share_content");
};

export const createContent: MutationFunction<AppShare, Partial<AppShare>> =
  async (state) => {
    return api.post("config/app_share_content", undefined, state);
  };

export const updateContent: MutationFunction<
  AppShare,
  { id: number; payload: Partial<AppShare> }
> = async ({ id, payload }) => {
  return api.patch(`config/app_share_content/${id}`, undefined, payload);
};

export const deleteContent: MutationFunction<AppShare, Partial<AppShare>> =
  async (state) => {
    return api.delete(`config/app_share_content/${state.id}`);
  };
