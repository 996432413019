import MaterialTable, { Column } from "material-table";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";
import { StudentProfile } from "../Student/Student";
import { ApiErrorLog } from "../typings/audit/ApiErrorLog";
import { deleteErrorLog, getAllErrorLogs } from "./queries";

export const ErrorLogTable = () => {
  const {
    params: { start_date, end_date },
  } = useRouteMatch<{ start_date: string; end_date: string }>();

  const {
    isLoading,
    data,
    isError,
  } = useQuery(
    `error_logs/${start_date}/${end_date}`,
    () => getAllErrorLogs(start_date, end_date),
    {  refetchOnWindowFocus: false, }
  );
  console.log(data)
  const queryClient = useQueryClient();

  const columnDefintion: Column<ApiErrorLog<StudentProfile>>[] = [
    {
      title: "Error code",
      field: "error_code",
    },
    {
      title: "Requested Date",
      field: "event_at",
      type: "date",
    },
    {
      title: "URL",
      field: "target_url",
    },
    {
      title: "Method",
      field: "http_request_method",
    },
    {
      title: "Error message",
      field: "error_message",
    },
  ];

  const { mutateAsync: mutDeleteErrorLog } = useMutation(deleteErrorLog, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  if (isLoading) {
    return <h1>Loading</h1>;
  }

  if (!data) {
    return <h1>No Data</h1>;
  }

  if (isError) {
    return <h1>Error</h1>;
  }
  return (
    <MaterialTable
      style={{ margin: "1em" }}
      isLoading={isLoading}
      title="Error Logs"
      data={data}
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowDelete: mutDeleteErrorLog,
      }}
    />
  );
};
