import {
  Card,
  CardContent,
  Grid,
  Slide,
  Snackbar,
  SnackbarCloseReason,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, {
  FC,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { api } from "../../api";
import { AppContext } from "../../AppContext";
import { LoginForm } from "./LoginForm";

const useLoginStyles = makeStyles({
  container: {
    height: "100vh",
  },
});

type SnackbarMetaType = {
  severity: "success" | "info" | "warning" | "error" | undefined;
  text: string;
};

type LoginFormikFields = {
  username: string;
  password: string;
};

export const Login: FC = () => {
  const styles = useLoginStyles();
  const locationContext = useLocation<{ from?: string }>();
  const historyContext = useHistory();
  const appContext = useContext(AppContext);
  const [snackbarMeta, setSnackbarMeta] =
    useState<SnackbarMetaType | null>(null);

  const initiateSignIn = async (values: LoginFormikFields) => {
    try {
      const { error, access_token, refresh_token } = await api.post(
        "auth/login",
        undefined,
        {
          username: values.username,
          password: values.password,
          grant_type: "password",
          type: "email",
        }
      );
      if (error) {
        throw new Error(error);
      }

      if (!access_token) {
        throw new Error("Couldn't get access token");
      }
      localStorage.setItem("refreshToken", refresh_token);

      api.setAccessToken(access_token);

      appContext.dispatch({
        type: "AUTH",
        payload: { isAuthenticated: true },
      });
      if (locationContext.state.from) {
        historyContext.replace(locationContext.state.from);
      }
    } catch (error) {
      setSnackbarMeta({ severity: "error", text: error.message });
    }
  };

  const handleClose = (event: SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMeta(null);
  };
  useEffect(() => {
    async function checkForAuth() {
      if (appContext.state.portal.isAuthenticated === undefined) {
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          console.log(refreshToken);
          const { error, access_token } = await api.post(
            "auth/renew",
            undefined,
            { refresh_token: refreshToken }
          );
          if (error) {
            throw new Error(error);
          }
          api.setAccessToken(access_token);
          appContext.dispatch({
            type: "AUTH",
            payload: { isAuthenticated: true },
          });
        } catch (error) {
          appContext.dispatch({
            type: "AUTH",
            payload: { isAuthenticated: false },
          });
        }
      }
    }
    checkForAuth();
  }, [appContext.state.portal.isAuthenticated, appContext]);

  if (appContext.state.portal.isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      alignContent="center"
      className={styles.container}
    >
      <Grid item xs={3}>
        <Card elevation={2}>
          <CardContent>
            <LoginForm onSubmit={initiateSignIn} />
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!snackbarMeta}
          autoHideDuration={1500}
          onClose={handleClose}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
          <Alert severity={snackbarMeta?.severity}>{snackbarMeta?.text}</Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};
