import { Column } from "material-table";
import moment from "moment";
import { HomeConfigurationDetailItem } from "../../types";

const itemTypeMap = {
  video: "Video",
  group: "Group",
  subject: "Subject",
  topic: "Topic",
  system: "System",
  promotion: "Promotion",
  live_stream: "Live Stream",
  flash_note: "Flash Note",
};

export const getConfigurationDetailColumns =
  (): Column<HomeConfigurationDetailItem>[] => {
    return [
      {
        title: "Title",
        field: "title",
        type: "string",
      },
      {
        title: "Description",
        field: "description",
        type: "string",
      },

      {
        title: "Type",
        field: "item_type",
        type: "string",
        lookup: itemTypeMap,
      },
      {
        title: "Display Order",
        field: "display_order",
        type: "numeric",
      },
      {
        title: "Valid From",
        field: "valid_from",
        type: "datetime",
        initialEditValue: moment().startOf("minute"),
        render: ({ valid_from }) => {
          return moment(valid_from)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD hh:mm A");
        },
      },
      {
        title: "Valid Through",
        field: "valid_through",
        type: "datetime",
        initialEditValue: moment().startOf("minute"),
        render: ({ valid_through }) => {
          return moment(valid_through)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD hh:mm A");
        },
      },
      {
        title: "Is Enabled?",
        field: "is_enabled",
        type: "boolean",
      },
    ];
  };
