export const bannerOptions = [
  { title: "Custom Image", value: "image" },
  { title: "Plain Text", value: "text" },
];

export const itemTypeOptions = [
  { value: "video", title: "Video" },
  { value: "group", title: "Group" },
  { value: "subject", title: "Subject" },
  { value: "topic", title: "Topic" },
  { value: "system", title: "System" },
  { value: "promotion", title: "Promotion" },
  { value: "live_stream", title: "Live Stream" },
  { value: "flash_note", title: "Flash Note" },
];

export const featuredOptions = [
  { value: "horizontal_scroll", title: "Horizontal Scroll" },
];

export const nonFeaturedOptions = [{ value: "grid", title: "Grid" }];
