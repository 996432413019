import { MutationFunction } from "react-query";
import { api } from "../../../api";
import { LiveStream } from "./types";

export const getAllLiveStreams = () => api.get("/media/live_streams");

// [TODO] REFACTOR
export const getAllMediaSources = () => api.get("/media/media_sources");

export const createLiveStream = (
  liveStream: Omit<LiveStream, "id"> | FormData
) => api.post(`/media/live_streams`, undefined, liveStream);

export const editLiveStream: MutationFunction<
  LiveStream,
  Partial<LiveStream>
> = (liveStream) => {
  const { id, ...payload } = liveStream;
  return api.patch(`/media/live_streams/${id}`, undefined, payload);
};

export const deleteLiveStream: MutationFunction<boolean, number> = (
  id: number
) => api.delete(`/media/live_streams/${id}`);
