import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AddBanner } from "./AddBanner";
import { BannerList } from "./BannerList";

export const AppBannerConfiguration = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <BannerList />} />
      <Route path={`${url}/new`} render={() => <AddBanner />} />
    </Switch>
  );
};
