import { MutationFunction } from "react-query";
import { api } from "../api";
import { S3BucketType } from "../shared/modelTypes";

export const getAllS3Bucket = () => {
  return api.get("config/s3_buckets");
};

export const createS3Bucket: MutationFunction<
  S3BucketType,
  Partial<S3BucketType>
> = async (bucket) => {
  return api.post("config/s3_buckets", undefined, bucket);
};

export const updateS3Bucket: MutationFunction<
  S3BucketType,
  { id: number; payload: Partial<S3BucketType> | FormData }
> = async ({ id, payload }) => {
  return api.patch(`config/s3_buckets/${id}`, undefined, payload);
};

export const deleteS3Bucket: MutationFunction<
  S3BucketType,
  Partial<S3BucketType>
> = async (bucket) => {
  return api.delete(`config/s3_buckets/${bucket.id}`);
};
