import MaterialTable, { Column } from 'material-table';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { State } from '../../../shared/modelTypes';
import * as React from 'react';
import {
  createCollege,
  deleteCollege,
  getAllColleges,
  updateCollege,
} from '../../../queries/college';
import { getAllState } from '../../../queries/state';
import Select from 'react-select';
import { reactSelectCustomStyle } from '../../../theme/theme';

export const Colleges = () => {
  const { isLoading, data: college = [] } = useQuery(
    'students/all-colleges',
    getAllColleges,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: state = [] } = useQuery('students/states', getAllState, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const columnDefintion: Column<State>[] = [
    {
      title: 'State',
      field: 'state_id',
      lookup: state.reduce(
        (acl: any, curr: any) => ({ ...acl, [curr.id]: curr.name }),
        {}
      ),
      editComponent: (props: any) => {
        const current = state
          .filter((item: any) => item.id === props.value)
          .map((item: any) => item.name);

        return (
          <Select
            value={{
              value: props.value,
              label: current,
            }}
            options={state.map((states: { id: any; name: any }) => ({
              value: states.id,
              label: states.name,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              props.onChange(event.value);
            }}
            menuPlacement='auto'
          />
        );
      },
    },
    {
      title: 'College',
      field: 'name',
    },
    {
      title: 'Order',
      field: 'list_order',
    },
  ];

  const queryClient = useQueryClient();

  const { mutateAsync: mutCreateCollege } = useMutation(createCollege, {
    onSuccess: (newCollege) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutDeleteCollege } = useMutation(deleteCollege, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateCollege } = useMutation(updateCollege, {
    onSuccess: (updatedCollege) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      data={college}
      title='College'
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutCreateCollege,
        onRowUpdate: (updatingBucket) =>
          mutUpdateCollege({
            id: updatingBucket.id,
            payload: updatingBucket,
          }),
        onRowDelete: mutDeleteCollege,
      }}
    />
  );
};
