import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { getAllSubjects } from "../../../../../queries/categories";
import { Subject } from "../../../../../shared/modelTypes";

export const SubjectSelector: FC<{ onSelect: (values: any[]) => void }> = ({
  onSelect,
}) => {
  const { data: subjects = [] } = useQuery<Subject[]>(
    "subjects",
    getAllSubjects,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const [selectedSubjects, selectSubjects] = useState<Subject[]>([]);
  return (
    <Grid container>
      <Grid item xs>
        <Autocomplete
          onChange={(_, values) => {
            selectSubjects(values);
            onSelect(values);
          }}
          filterSelectedOptions
          multiple
          options={subjects}
          getOptionLabel={(option) => option.title}
          value={selectedSubjects}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Subjects"
              placeholder="Subjects"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
