import MaterialTable, { Column } from "material-table";
import moment from "moment-timezone";
import * as React from "react";
import { useQuery } from "react-query";
import { getStudentDevices } from "../../queries/student";
import { StudentDevices } from "../../shared/modelTypes";

export const StudentDevice = (props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) => {
  const { children, value, index, ...other } = props;

  const { isLoading, data: devices = [] } = useQuery(
    ["students/devices", children],
    () => getStudentDevices(children),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const columnDefintion: Column<StudentDevices>[] = [
    {
      title: "Type",
      field: "device_type",
    },
    {
      title: "Device ID",
      field: "device_id",
    },
    {
      title: "Token",
      field: "device_token",
    },
    {
      title: "FCM Token",
      field: "fcm_token",
    },
    {
      title: "Advertisement ID",
      field: "advertisement_id",
    },
    {
      title: "Created at",
      field: "created_at",
      type: "date",
      render: ({ created_at }) => {
        return moment(created_at)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD hh:mm A');
      },
    },
    {
      title: "Is active",
      field: "is_active",
      type: "boolean",
    },
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MaterialTable
          isLoading={isLoading}
          title="Devices"
          data={devices}
          columns={columnDefintion.map((c) => ({
            ...c,
            tableData: undefined,
          }))}
        />
      )}
    </div>
  );
};
